<template>
    <modal class="modal-assign-vehicle-user" @close="closeModal()">
        <h3 slot="header" class="modal-title">
            {{ $t("assign_vehicle_and_driver") }}
        </h3>
        <div slot="body" class="flex gap-10">
            <!-- Vehicles Column -->
            <div>
                <div>
                    <md-field>
                        <label>{{ $t("license_plate") }}</label>
                        <md-input v-model="searchVehicle" maxlength="50"></md-input>
                    </md-field>
                </div>
                <div class="flex gap-4 justify-end items-center">
                    <button
                            @click="clearFilterVehicle()"
                            class="btn-secondary font-bold py-2 px-4 rounded-xl md:w-40"
                        >
                            {{ $t("delete_filter") }}
                        </button>
                    <button
                        @click="filterVehicles()"
                        class="btn-primary font-bold py-2 px-4 rounded-xl"
                    >
                        {{ $t("search") }}
                    </button>
                </div>
                <div>
                    <VehicleList v-model="vehicle_selected" :vehicles="vehicles"/>
                </div>
                <!-- Pagination -->
                <div class="flex justify-center pb-6" v-if="num_vehicles > perPage">
                    <pagination v-model="page" :noArrows="true" :perPage="perPage" :total="num_vehicles"/>
                </div>
            </div>
            <!-- Driver Column -->
            <div>
                <div>
                    <md-field>
                        <label>{{ $t("enter_text") }}</label>
                        <md-input v-model="searchDriver" maxlength="50"></md-input>
                    </md-field>
                </div>
                <div class="flex gap-4 justify-end items-center">
                    <button
                            @click="clearFilterDriver()"
                            class="btn-secondary font-bold py-2 px-4 rounded-xl md:w-40"
                        >
                            {{ $t("delete_filter") }}
                        </button>
                    <button
                        @click="filterDrivers()"
                        class="btn-primary font-bold py-2 px-4 rounded-xl"
                    >
                        {{ $t("search") }}
                    </button>
                </div>
            <div>
                <DriverList v-model="user_selected" :users="users" />
            </div>
            </div>
        </div>
        <div slot="footer" class="flex justify-end items-center gap-4">
            <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
            <button @click="closeModal()"
                class="btn-secondary font-bold py-2 px-4 rounded-xl">
                {{ $t("cancel") }}
            </button>
            <button @click="assignVehicleUser()" class="btn-primary font-bold py-2 px-4 rounded-xl">
                {{ $t("assign_vehicle_and_driver") }}
            </button>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import VehicleList from "@/components/pages/Services/Modals/VehicleList.vue";
import DriverList from "@/components/pages/Services/Modals/DriverList.vue";
import { getUsers } from '@/services/users';
import { getProviderVehicles } from '@/services/vehicles';
import { assignServicesToVehicle, assignServicesToUser } from '@/services/services';
import authMixin from "@/mixins/auth";
import paginationMixin from "@/mixins/pagination";
import { Roles } from '@/config';
export default {
    props: ["services"],
    mixins: [authMixin, paginationMixin],
    components: {
		Modal,
        VehicleList,
        DriverList,
	},
    data() {
        return {
            loading: false,
            searchVehicle: "",
            searchDriver: "",
            filteredDrivers: false,
            vehicle_selected: null,
            num_vehicles: 0,
            vehicles: [],
            user_selected: null,
            users: [],
        };
    },

    computed: {
        servicesUUIDs() {
            let servicesUUIDs = [];
            for (const service of this.services) {
                servicesUUIDs.push(service.idService);
            }
            return servicesUUIDs;
        },
    },

    watch: {
		page() {
            this.fetchVehicles();
        }
	},

    created: async function () {
        await this.fetchVehicles();
        await this.fetchUsers();
    },

    methods: {
        closeModal() {
            this.$emit('close');
        },

        async filterVehicles() {
            await this.fetchVehicles();
        },

        async filterDrivers() {
            if (this.filteredDrivers) {
                await this.fetchUsers();
            }
            this.filteredDrivers = true;
            const searchString = this.searchDriver.toLowerCase();
            let filtered_users = this.users.filter(function (user) {
                const name = user.name.toLowerCase();
				const familyName = user.familyName.toLowerCase();
				const email = user.email.toLowerCase();
				if (name.includes(searchString) || familyName.includes(searchString) || email.includes(searchString)) {
                    return user;
                }
			});
			this.users = filtered_users;
        },

        async clearFilterVehicle() {
            this.searchVehicle = "";
            await this.fetchVehicles();
        },

        async clearFilterDriver() {
            this.searchDriver = "";
            await this.fetchUsers();
        },

        async fetchVehicles() {
            this.loading = true;
            // Check user role
            const currentUserRole = await this.getCurrentUserRole();
            let filterIdProvider;
            if (currentUserRole == Roles.PROVIDERADMIN) {
                filterIdProvider = await this.getCurrentUserProvider();
            } else {
                filterIdProvider = this.services[0].provider.idProvider;
            }
            const response = await getProviderVehicles(filterIdProvider, this.page, this.searchVehicle);
            this.num_vehicles = response.numVehicles;
            this.vehicles = response.vehicles;
            this.loading = false;
        },

        async fetchUsers() {
            this.loading = true;
            this.users = await getUsers();
            let driver_users = this.users.filter(function (user) {
					return user.role == "driver";
				});
            // Check user role & filter drivers with own provider (ProviderAdmin) or service provider (Admin)
            const currentUserRole = await this.getCurrentUserRole();
            let filterIdProvider;
            if (currentUserRole == Roles.ADMIN) {
                filterIdProvider = this.services[0].provider.idProvider;
            } else if (currentUserRole == Roles.PROVIDERADMIN) {
                filterIdProvider = await this.getCurrentUserProvider();
            }
            driver_users = driver_users.filter(function (user) {
                return user.provider.idProvider == filterIdProvider;
            });
            this.users = driver_users;
            this.loading = false;
        },

        async assignVehicleUser() {
            this.loading = true;
            if (!this.vehicle_selected) {
                this.$noty.warning(this.$t("warning.no_vehicle"));
                this.loading = false;
                return;
            }
            if (!this.user_selected) {
                this.$noty.warning(this.$t("warning.no_driver"));
                this.loading = false;
                return;
            }
            const responseVehicle = await assignServicesToVehicle(this.vehicle_selected, this.servicesUUIDs);
            const responseUser = await assignServicesToUser(this.user_selected, this.servicesUUIDs);
            if (responseVehicle && responseUser) {
                this.$noty.success(this.$t('success.assign_vehicle_and_driver'));
                this.$emit('close');
            } else {
                this.$noty.error(this.$t('error.saved'));
            }
            this.loading = false;
        }
    }
}
</script>