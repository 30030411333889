import { SUPPORTED_LOCALES } from '../config';

export default {
    methods: {
        // Returns locale configuration
        getLocaleId(code) {
            let locale = SUPPORTED_LOCALES.find(loc => loc.code === code);
            if (locale) {
                return locale.id;
            }
        },

        configDatePickerLocale() {
			if (this.$i18n.locale == "es") {
				this.$material.locale = {
					startYear: 1900,
					endYear: 2099,
					dateFormat: 'dd/MM/yyyy',
					days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
					shortDays: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
					shorterDays: ['D', 'L', 'M', 'Mi', 'J', 'V', 'S'],
					months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
					shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'],
					shorterMonths: ['E', 'F', 'M', 'A', 'M', 'Jun', 'Jul', 'A', 'Se', 'O', 'N', 'D'],
					firstDayOfAWeek: 0,
					cancel: 'Cancelar',
					confirm: 'Ok'
				};
			}
		},
    }
}