<template>
    <modal class="modal-images" @close="closeModal()">
        <div slot="header">
            <button class="modal-close" @click="closeModal()">
                <md-icon>close</md-icon>
            </button>
        </div>
        <div slot="body">
            <agile :options="slide_options">
                <div v-for="(image, index) in images" :key="index" class="slide images-slide">
                    <img :src="image" />
                </div>
            </agile>
        </div>
    </modal>
</template>

<script>
import { VueAgile } from 'vue-agile'
import Modal from '@/components/Modal.vue';
export default {
    props: ["images"],
    components: {
		Modal,
        agile: VueAgile,
	},
    data() {
        return {
            loading: false,
            slide_options: {
                autoplay: false,
                centerMode: true,
            },
        };
    },

    methods: {
        closeModal() {
            this.$emit('close');
        },
    }
}
</script>

<style scoped>
.modal-header {
    position: relative;
}
.modal-header button.modal-close {
    position: absolute;
    top: -10px;
    right: -10px;
    background: white;
    border: 1px solid var(--secondary-color);
    border-radius: 9999px;
}
div.images-slide {
    display: flex;
    justify-content: center;
}
</style>