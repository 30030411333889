<template>
    <div>
		<!-- Toolbar -->
		<div class="bg-white pt-6 md:px-12">
			<router-link :to="{ name: createLink }" tag="button"
				class="btn-add float-right">
				{{ $t("add") }}
			</router-link>
			<md-field>
				<label>{{ $t("enter_text") }}</label>
				<md-input v-model="search" maxlength="50"></md-input>
			</md-field>
			<div class="flex items-center gap-10">
				<md-field>
					<label for="movie">{{ $t('fields.provider') }}</label>
					<md-select v-model="search_provider" name="search_provider" id="search_provider" :placeholder="$t('fields.provider')" :disabled="!isAdmin">
						<md-option v-for="(provider, index) in providers" :key="index" :value="provider.idProvider">
							{{ provider.name }}
						</md-option>
					</md-select>
				</md-field>
				<!-- Search buttons -->
				<div class="grow flex gap-2">
					<button @click="clearFilter()" class="btn-secondary font-bold py-2 px-4 rounded-xl md:w-40" :disabled="!filtered">
						{{ $t("delete_filter") }}
					</button>
					<button @click="filter()" class="btn-primary font-bold py-2 px-4 rounded-xl">
						{{ $t("search") }}
					</button>
				</div>
			</div>
		</div>
		<!-- Table -->
		<div class="pt-6 md:px-12">
			<table class="table-rows-rounded min-w-full">
				<thead class="underline text-center">
					<tr>
						<th>
							{{ $t("name") }}
						</th>
						<th>
							{{ $t("email") }}
						</th>
						<th>
							{{ $t("provider") }}
						</th>
						<th>
							{{ $t("last_access") }}
						</th>
						<th>
							<!-- Buttons -->
						</th>
					</tr>
				</thead>
				<tbody class="text-center">
					<tr v-for="(user, index) in users.slice(minPosition, maxPosition)" :key="index">
						<td>
							{{ user.name }} {{ user.familyName }}
						</td>
						<td>
							{{ user.email }}
						</td>
						<td>
							{{ user.provider.name }}
						</td>
						<td>{{ moment(user.lastAccess).format('YYYY-MM-DD HH:MM') }}</td>
						<td class="text-right">
							<div class="table-crud-buttons">
								<router-link tag="button" :to="{ 
									name: detailLink,
										params: {
											user: user,
											editing: false,
										},
									}">
									<img :src="require('@/assets/icons/icons_actions_view.svg')"/>
								</router-link>
								<router-link tag="button" :to="{ 
									name: detailLink,
										params: {
											user: user,
											editing: true,
										},
									}">
									<img :src="require('@/assets/icons/icons_actions_edit.svg')"/>
								</router-link>
								<button @click="confirmDelete(user)">
									<img :src="require('@/assets/icons/icons_actions_delete.svg')"/>
								</button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<!-- Pagination -->
            <div class="flex justify-center pb-6">
                <pagination v-model="page" :noArrows="true" :perPage="perPage" :total="users.length"/>
            </div>
		</div>
		<modal v-if="showConfirm" @close="showConfirm = false">
			<h3 slot="header">
				{{ $t("confirm") }}
			</h3>
			<div slot="body">
				<i18n v-if="onlyDrivers" path="confirm_messages.delete_driver" tag="p">
					<template #driver>
						{{ userToDelete.email }}
					</template>
				</i18n>
				<i18n v-else path="confirm_messages.delete_user" tag="p">
					<template #user>
						{{ userToDelete.email }}
					</template>
				</i18n>
			</div>
			<div slot="footer" class="flex gap-4">
				<button @click="showConfirm = false"
					class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-xl">
					{{ $t("cancel") }}
				</button>
				<button @click="deleteUser(userToDelete)"
					class="btn-secondary font-bold py-2 px-4 rounded-xl">
					{{ $t("delete") }}
				</button>
			</div>
		</modal>
    </div>
</template>
<script>
// import { API, Auth } from 'aws-amplify';
import Modal from '@/components/Modal.vue';
import { getServiceFilters } from "@/services/services";
import { getUsers, deleteUser } from '@/services/users';
import authMixin from "@/mixins/auth";
import paginationMixin from "@/mixins/pagination";
import { Roles } from '@/config';
export default {
	mixins: [authMixin , paginationMixin],
	components: {
		Modal,
	},
    data() {
        return {
			loading: false,
			showConfirm: false,
			onlyDrivers: false,
			createLink: this.$i18n.locale + '.user_create',
			detailLink: this.$i18n.locale + '.user_detail',
			userToDelete: {},
			filtered: false,
			search: "",
			search_provider: null,
            users: [],
			providers: [],
        };
    },

	computed: {
		currentRouteName() {
			return this.$route.name.substr(3);
		},
	},

	created: async function() {
		this.checkPermissions();
		this.loading = true;
		if(this.currentRouteName == "drivers") {
			this.onlyDrivers = true;
			this.createLink = this.$i18n.locale + '.driver_create';
			this.detailLink = this.$i18n.locale + '.driver_detail';
		}
		await this.fetchUsers();
		await this.fetchProviders();
		this.loading = false;
	},

    methods: {
		async fetchUsers() {
			this.users = await getUsers();
			if (this.onlyDrivers) {
				let driver_users = this.users.filter(function (user) {
					return user.role == "driver";
				});
				// Filter own drivers for ProviderAdmin role
				const currentUserRole = await this.getCurrentUserRole();
				if (currentUserRole == Roles.PROVIDERADMIN) {
					const currentUserProvider = await this.getCurrentUserProvider();
					driver_users = driver_users.filter(function (user) {
						return user.provider.idProvider == currentUserProvider;
					});
				}
				this.users = driver_users;
			}
		},

		async fetchProviders() {
			const serviceFilters = await getServiceFilters();
            this.providers = serviceFilters.providers;
		},

		confirmDelete(user) {
			this.userToDelete = user;
			this.showConfirm = true;
		},

		async filter() {
			// Reload all users if previously filtered
			if (this.filtered) {
				await this.fetchUsers();
			}
			this.filtered = true;
			const searchString = this.search.toLowerCase();
			const searchProvider = this.search_provider;
			
			let filtered_users = this.users.filter(function (user) {
				const name = user.name.toLowerCase();
				const familyName = user.familyName.toLowerCase();
				const email = user.email.toLowerCase();
				const idProvider = user.provider.idProvider;
				if (searchString && !searchProvider) {
					return name.includes(searchString) || familyName.includes(searchString) || email.includes(searchString); 
				}
				if (searchProvider && !searchString) {
					return idProvider == searchProvider;
				}
				if (searchProvider && searchString) {
					return (name.includes(searchString) || familyName.includes(searchString) || email.includes(searchString)) && idProvider == searchProvider;
				}
			});
			this.users = filtered_users;
		},

		async clearFilter() {
			this.filtered = false;
			this.search = "";
			this.search_provider = null;
			await this.fetchUsers();
		},

		async deleteUser(user) {
			const response = await deleteUser(user);
			if (response) {
				await this.fetchUsers();
				this.userToDelete = {};
				const success_msg = this.onlyDrivers ? this.$t('success.driver_deleted') : this.$t('success.user_deleted');
				this.$noty.success(success_msg);
			} else {
				this.$noty.error(this.$t('error.delete'));
			}
			this.showConfirm = false;
		}
    },
	
};
</script>