import { API, Auth } from 'aws-amplify';
import util from 'util';

/**
 * Get providers list
 *
 * @param {string} filter - Filter string
 * @returns {Promise} Promise - object with the result of the api call response
 */
const getProviders = async (page = 1, filter = null) => {
    let responseData = {};
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/provider'];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const queryStringParameters = {
        page: page,
        filter: filter,
    };
    const myInit = {
        headers: headers,
        response: true,
        queryStringParameters: queryStringParameters,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        responseData = response.data;
    } catch (error) {
        console.error(
            "Error getting providers: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return responseData;
}

/**
 * Create provider
 *
 * @param {Object} provider - provider object
 * @returns {Promise} Promise - object with the result of the api call response
 */
 const createProvider = async (provider) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/provider'];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: provider,
    };
    try {
        var response = await API.post(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error creating provider: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return error.response.status;
    }
}

/**
 * Update provider
 *
 * @param {Object} provider - provider object
 * @returns {Promise} Promise - object with the result of the api call response
 */
 const updateProvider = async (provider) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/provider/' + provider.idProvider];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: provider,
    };
    try {
        var response = await API.put(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error updating provider: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
}

/**
 * Delete provider
 *
 * @param {Object} provider - provider object
 * @returns {Promise} Promise - object with the result of the api call response
 */
 const deleteProvider = async (provider) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/provider/' + provider.idProvider];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.del(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error deleting provider: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
}



export {
    createProvider,
    updateProvider,
    deleteProvider,
    getProviders,
}