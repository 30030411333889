import { API, Auth } from "aws-amplify";
import util from "util";

/**
 * Get Services list
 *
 * @param {string} filter - Filter string
 * @param {Date} filterDateFrom - Date From Filter
 * @param {Date} filterDateUntil - Date Until Filter
 * @param {string} filterOpenClosed - Filter Open Closed Services
 * @param {string} filterStatus - Filter Service Status
 * @param {uuid} filterProvider - Filter Provider by UUID
 * @param {string} filterWasteType - Filter Waste Type Name
 * @param {string} filterLogisctisCenters - Filter Logistics Centers
 * @param {string} filterLicensePlate - Filter Vehicle License Plate
 * @param {string} filterDriver - Filter Driver by cognito sub
 * @returns {Promise} Promise - object with the result of the api call response
 */
const getServices = async (
    page = 1,
    filter = null,
    filterDateFrom = null,
    filterDateUntil = null,
    filterOpenClosed = null,
    filterStatus = null,
    filterProvider = null,
    filterWasteType = null,
    filterLogisticsCenters = null,
    filterLicensePlate = null,
    filterDriver = null,
) => {
    let responseData = {};
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ["/service"];
    var headers = {
        "x-api-key": API_KEY,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const filterLogisticsCentersQueryString = filterLogisticsCenters.toString();
    const filterWasteTypeQueryString = filterWasteType.toString();
    const queryStringParameters = {
        page: page,
        filter: filter,
        filterDateFrom: filterDateFrom,
        filterDateUntil: filterDateUntil,
        filterOpenClosed: filterOpenClosed,
        filterStatus: filterStatus,
        filterProvider: filterProvider,
        filterWasteType: filterWasteTypeQueryString,
        filterLogisticsCenters: filterLogisticsCentersQueryString,
        filterLicensePlate: filterLicensePlate,
        filterDriver: filterDriver,
    };
    const myInit = {
        headers: headers,
        response: true,
        queryStringParameters: queryStringParameters,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        responseData = response.data;
    } catch (error) {
        console.error(
            "Error getting services: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return responseData;
};

/**
 * Get Provider Services list
 *
 * @param {uuid} idProvider - Provider UUID
 * @param {string} filter - Filter string
 * @param {Date} filterDateFrom - Date From Filter
 * @param {Date} filterDateUntil - Date Until Filter
 * @param {string} filterOpenClosed - Filter Open Closed Services
 * @param {string} filterStatus - Filter Service Status
 * @param {string} filterWasteType - Filter Waste Type Name
 * @param {string} filterLogisticsCenters - Filter Logistics Centers
 * @param {string} filterLicensePlate - Filter Vehicle License Plate
 * @param {string} filterDriver - Filter Driver by cognito sub
 * @returns {Promise} Promise - object with the result of the api call response
 */
 const getProviderServices = async (
    page = 1,
    idProvider,
    filter = null,
    filterDateFrom = null,
    filterDateUntil = null,
    filterOpenClosed = null,
    filterStatus = null,
    filterWasteType = null,
    filterLogisticsCenters = null,
    filterLicensePlate = null,
    filterDriver = null,
) => {
    let responseData = {};
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ["/provider/" + idProvider + "/service"];
    var headers = {
        "x-api-key": API_KEY,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const filterLogisticsCentersQueryString = filterLogisticsCenters.toString();
    const filterWasteTypeQueryString = filterWasteType.toString();
    const queryStringParameters = {
        page: page,
        filter: filter,
        filterDateFrom: filterDateFrom,
        filterDateUntil: filterDateUntil,
        filterOpenClosed: filterOpenClosed,
        filterStatus: filterStatus,
        filterWasteType: filterWasteTypeQueryString,
        filterLogisticsCenters: filterLogisticsCentersQueryString,
        filterLicensePlate: filterLicensePlate,
        filterDriver: filterDriver,
    };
    const myInit = {
        headers: headers,
        response: true,
        queryStringParameters: queryStringParameters,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        responseData = response.data;
    } catch (error) {
        console.error(
            "Error getting provider services: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return responseData;
};

/**
 * Get Service by UUID
 *
 * @param {uuid} idService - Service UUID
 * @returns {Promise} Promise - object with the result of the api call response
 */
const getServiceByUUID = async (idService) => {
    let service = {};
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ["/service/" + idService];
    var headers = {
        "x-api-key": API_KEY,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        service = response.data;
    } catch (error) {
        console.error(
            "Error getting provider services: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return service;
};

/**
 * Assign Services to vehicle
 *
 * @param {uuid} idVehicle - Vehicle UUID
 * @param {Array} services - Service UUIDs Array
 * @returns {Promise} Promise - object with the result of the api call response
 */
const assignServicesToVehicle = async (idVehicle, services) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ["/provider/idProvider/vehicle/" + idVehicle + "/assignServices"];
    var headers = {
        "x-api-key": API_KEY,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: services,
    };
    try {
        var response = await API.post(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error assigning vehicle to services: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
};

/**
 * Assign Services to user
 *
 * @param {uuid} idUser - User cognito sub UUID
 * @param {Array} services - Service UUIDs Array
 * @returns {Promise} Promise - object with the result of the api call response
 */
 const assignServicesToUser = async (idUser, services) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ["/provider/idProvider/user/" + idUser + "/assignServices"];
    var headers = {
        "x-api-key": API_KEY,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: services,
    };
    try {
        var response = await API.post(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error assigning usere to services: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
};

/**
 * Desassign Services from user
 *
 * @param {Array} services - Service External Ids Array
 * @returns {Promise} Promise - object with the result of the api call response
 */
const desassignServicesFromUser = async (services) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ["/service/user/desassignServices"];
    var headers = {
        "x-api-key": API_KEY,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: services,
    };
    try {
        var response = await API.post(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error desassigning services's drivers: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
};

/**
 * Cancel Service
 *
 * @param {uuid} idService - Service UUID
 * @returns {Promise} Promise - object with the result of the api call response
 */
const cancelService = async (idService) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ["/service/" + idService];
    var headers = {
        "x-api-key": API_KEY,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.del(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error canceling service: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
};

/**
 * Change Services Date
 *
 * @param {Array} services - Service UUIDs Array
 * @param {date} date - Date format YYYY-MM-DD
 * @returns {Promise} Promise - object with the result of the api call response
 */
const changeServiceDate = async (services, date) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ["/service/changeDate"];
    var headers = {
        "x-api-key": API_KEY,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const body = {
        services: services,
        date: date,
    };
    const myInit = {
        headers: headers,
        response: true,
        body: body,
    };
    try {
        var response = await API.post(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error canceling service: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
};

/**
 * Get Services Filters
 *
 * @returns {Promise} Promise - object with the result of the api call response
 */
 const getServiceFilters = async () => {
    let serviceFilters = {};
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ["/serviceFilters"];
    var headers = {
        "x-api-key": API_KEY,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        serviceFilters = response.data;
    } catch (error) {
        console.error(
            "Error getting service filters: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return serviceFilters;
};

/**
 * Get Services Amounts list for CSV export
 *
 * @param {string} filter - Filter string
 * @param {Date} filterDateFrom - Date From Filter
 * @param {Date} filterDateUntil - Date Until Filter
 * @param {string} filterOpenClosed - Filter Open Closed Services
 * @param {string} filterStatus - Filter Service Status
 * @param {uuid} filterProvider - Filter Provider by UUID
 * @param {string} filterWasteType - Filter Waste Type Name
 * @param {Array} filterLogisticsCenters - Filter Logistics Centers by Code
 * @returns {Promise} Promise - object with the result of the api call response
 */
const getServicesAmounts = async (
    filter = null,
    filterDateFrom = null,
    filterDateUntil = null,
    filterOpenClosed = null,
    filterStatus = null,
    filterProvider = null,
    filterWasteType = null,
    filterLogisticsCenters = null,
) => {
    let responseData = {};
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ["/service/export"];
    var headers = {
        "x-api-key": API_KEY,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const filterLogisticsCentersQueryString = filterLogisticsCenters.toString();

    const queryStringParameters = {
        filter: filter,
        filterDateFrom: filterDateFrom,
        filterDateUntil: filterDateUntil,
        filterOpenClosed: filterOpenClosed,
        filterStatus: filterStatus,
        filterProvider: filterProvider,
        filterWasteType: filterWasteType,
        filterLogisticsCenters: filterLogisticsCentersQueryString,
    };
    const myInit = {
        headers: headers,
        response: true,
        queryStringParameters: queryStringParameters,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        responseData = response.data;
    } catch (error) {
        console.error(
            "Error exporting services: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return responseData;
};

/**
 * Get Services Tickets list for CSV export
 *
 * @param {string} filter - Filter string
 * @param {Date} filterDateFrom - Date From Filter
 * @param {Date} filterDateUntil - Date Until Filter
 * @param {string} filterOpenClosed - Filter Open Closed Services
 * @param {string} filterStatus - Filter Service Status
 * @param {uuid} filterProvider - Filter Provider by UUID
 * @param {string} filterWasteType - Filter Waste Type Name
 * @param {Array} filterLogisticsCenters - Filter Logistics Centers by Code
 * @returns {Promise} Promise - object with the result of the api call response
 */
const getServicesTickets = async (
    filter = null,
    filterDateFrom = null,
    filterDateUntil = null,
    filterOpenClosed = null,
    filterStatus = null,
    filterProvider = null,
    filterWasteType = null,
    filterLogisticsCenters = null,
) => {
    let responseData = {};
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ["/service/ticket/export"];
    var headers = {
        "x-api-key": API_KEY,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const filterLogisticsCentersQueryString = filterLogisticsCenters.toString();

    const queryStringParameters = {
        filter: filter,
        filterDateFrom: filterDateFrom,
        filterDateUntil: filterDateUntil,
        filterOpenClosed: filterOpenClosed,
        filterStatus: filterStatus,
        filterProvider: filterProvider,
        filterWasteType: filterWasteType,
        filterLogisticsCenters: filterLogisticsCentersQueryString,
    };
    const myInit = {
        headers: headers,
        response: true,
        queryStringParameters: queryStringParameters,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        responseData = response.data;
    } catch (error) {
        console.error(
            "Error exporting services: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return responseData;
};

export { 
    getServices,
    getServicesAmounts,
    getServicesTickets,
    getProviderServices,
    getServiceByUUID,
    cancelService,
    getServiceFilters,
    assignServicesToVehicle,
    assignServicesToUser,
    desassignServicesFromUser,
    changeServiceDate,
};
