var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white pb-4 md:px-12"},[_c('div',{staticClass:"flex justify-end"},[_c('a',{staticClass:"back-button",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("arrow_back")]),_vm._v(" "+_vm._s(_vm.$t("back"))+" ")])]),_c('ValidationObserver',{ref:"form",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('md-field',{class:[
            { 'md-valid': errors['license_plate'] && !errors['license_plate'][0] && _vm.touchedFields.name },
            { 'md-error': errors['license_plate'] && errors['license_plate'][0] }
            ]},[_c('label',{attrs:{"for":"license_plate"}},[_vm._v(_vm._s(_vm.$t('fields.license_plate')))]),_c('validation-provider',{attrs:{"name":"license_plate","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var touched = ref.touched;
return [_c('md-input',{attrs:{"name":"license_plate","type":"text","placeholder":_vm.$t('fields_text.license_plate'),"id":"license_plate","disabled":_vm.loading || _vm.view},model:{value:(_vm.license_plate),callback:function ($$v) {_vm.license_plate=$$v},expression:"license_plate"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"error"},[_vm._v("close")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('md-field',[_c('validation-provider',{attrs:{"name":"motorized","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('label',{attrs:{"for":"movie"}},[_vm._v(_vm._s(_vm.$t('fields.motorized')))]),_c('md-select',{attrs:{"name":"motorized","id":"motorized","placeholder":_vm.$t('fields.motorized'),"disabled":_vm.loading || _vm.view},model:{value:(_vm.motorized),callback:function ($$v) {_vm.motorized=$$v},expression:"motorized"}},[_c('md-option',{attrs:{"value":true}},[_vm._v(" "+_vm._s(_vm.$t("yes"))+" ")]),_c('md-option',{attrs:{"value":false}},[_vm._v(" "+_vm._s(_vm.$t("no"))+" ")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('md-field',[_c('validation-provider',{attrs:{"name":"active","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('label',{attrs:{"for":"movie"}},[_vm._v(_vm._s(_vm.$t('fields.active')))]),_c('md-select',{attrs:{"name":"active","id":"motorized","placeholder":_vm.$t('fields.active'),"disabled":_vm.loading || _vm.view},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('md-option',{attrs:{"value":true}},[_vm._v(" "+_vm._s(_vm.$t("yes"))+" ")]),_c('md-option',{attrs:{"value":false}},[_vm._v(" "+_vm._s(_vm.$t("no"))+" ")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('md-field',[_c('validation-provider',{attrs:{"name":"provider","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('label',{attrs:{"for":"movie"}},[_vm._v(_vm._s(_vm.$t('fields.provider')))]),_c('md-select',{attrs:{"name":"provider","id":"provider","placeholder":_vm.$t('fields.provider'),"disabled":_vm.loading || _vm.view || _vm.forceProvider},model:{value:(_vm.provider),callback:function ($$v) {_vm.provider=$$v},expression:"provider"}},_vm._l((_vm.providers),function(provider,index){return _c('md-option',{key:index,attrs:{"value":provider.idProvider}},[_vm._v(" "+_vm._s(provider.name)+" ")])}),1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('md-field',[_c('label',{attrs:{"for":"haulier_code"}},[_vm._v(_vm._s(_vm.$t('fields.haulier_code')))]),_c('md-input',{attrs:{"name":"haulier_code","type":"text","placeholder":_vm.$t('fields.haulier_code'),"id":"haulier_code","disabled":true},model:{value:(_vm.haulier_code),callback:function ($$v) {_vm.haulier_code=$$v},expression:"haulier_code"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && _vm.touched),expression:"errors[0] && touched"}],staticClass:"error"},[_vm._v("close")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)],1),_c('div',{staticClass:"flex justify-end gap-4 mt-4"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.editing || _vm.view),expression:"editing || view"}],staticClass:"btn-secondary font-bold py-2 px-4 rounded-xl md:w-40",attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();_vm.showConfirm = true}}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]),_c('button',{staticClass:"btn-primary font-bold py-2 px-4 rounded-xl",attrs:{"type":"submit","disabled":_vm.loading || _vm.view},on:{"click":function($event){$event.preventDefault();return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }