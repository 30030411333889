<template>
    <div class="bg-white pt-6 pb-3 md:px-12">
        <!-- Back button -->
        <div class="flex justify-end">
            <a class="back-button" @click="$router.go(-1)">
                <i class="material-icons">arrow_back</i>
                {{ $t("back") }}
            </a>
        </div>
        <ValidationObserver v-slot="{ handleSubmit, errors }">
            <form @submit.prevent="handleSubmit(save)">
                <!-- Name & Family Name -->
                <div class="grid grid-cols-2 gap-4">
                    <md-field :class="[
                    { 'md-valid': errors['name'] && !errors['name'][0] && touchedFields.name },
                    { 'md-error': errors['name'] && errors['name'][0] }
                    ]">
                    <label for="name">{{ $t('fields.name') }}</label>
                    <validation-provider name='name' rules="required" v-slot="{ errors, touched }" mode="aggressive">
                        <md-input v-model="name" name="name" type="text" :placeholder="$t('fields_text.name')" id="name" :disabled="loading || !editing || !allowedEdit"></md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                    </md-field>

                    <md-field :class="[
                        { 'md-valid': errors['family_name'] && !errors['family_name'][0] && touchedFields.family_name },
                        { 'md-error': errors['family_name'] && errors['family_name'][0] }
                        ]">
                        <label for="name">{{ $t('fields.family_name') }}</label>
                        <validation-provider name='family_name' rules="required" v-slot="{ errors, touched }" mode="aggressive">
                            <md-input v-model="family_name" name="family_name" type="text" :placeholder="$t('fields_text.family_name')" id="family_name" :disabled="loading || !editing || !allowedEdit"></md-input>
                            <slide-y-down-transition>
                                <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                            </slide-y-down-transition>
                            <span class="md-error">{{ errors[0] }}</span>
                        </validation-provider>
                    </md-field>
                </div>
                <!-- Email -->
                <div>
                    <md-field :class="[
                        { 'md-valid': errors['email'] && !errors['email'][0] && touchedFields.email },
                        { 'md-error': errors['email'] && errors['email'][0] }
                        ]">
                        <label for="email">{{ $t('fields.email') }}</label>
                        <validation-provider name='email' rules="required|email" v-slot="{ errors, touched }" mode="aggressive">
                            <md-input v-model="email" name="email" type="email" :placeholder="$t('fields_text.email')" id="email" :disabled="true"></md-input>
                            <slide-y-down-transition>
                                <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                            </slide-y-down-transition>
                            <span class="md-error">{{ errors[0] }}</span>
                        </validation-provider>
                    </md-field>
                </div>
                <!-- Provider & Role -->
                <div class="grid grid-cols-2 gap-4">

                    <!-- Role -->
                    <md-field v-if="!onlyDrivers">
                        <validation-provider name='role' rules="required" v-slot="{ errors }" mode="aggressive">
                            <label for="movie">{{ $t('fields.role') }}</label>
                            <md-select v-model="role" name="role" id="role" :placeholder="$t('fields.role')" :disabled="loading || !editing || !allowedEdit">
                                <md-option v-for="(role, index) in roles" :key="index" :value="role" 
                                    :disabled="role == 'admin' && currentUserRole == 'providerAdmin'">
                                    {{ $t('roles.' + role) }}
                                </md-option>
                            </md-select>
                            <span class="md-error">{{ errors[0] }}</span>
                        </validation-provider>
                    </md-field>
                    <!-- Provider -->
                    <md-field>
                        <validation-provider name='provider' :rules="onlyDrivers ? 'required': ''" v-slot="{ errors }" mode="aggressive">
                            <label for="movie">{{ $t('fields.provider') }}</label>
                            <md-select v-model="provider" name="provider" id="provider" :placeholder="$t('fields.provider')" :disabled="loading || !editing || !allowedEdit || forceProvider || disableProvider">
                                <md-option v-for="(provider, index) in providers" :key="index" :value="provider.idProvider">
                                    {{ provider.name }}
                                </md-option>
                            </md-select>
                            <span class="md-error">{{ errors[0] }}</span>
                        </validation-provider>
                    </md-field>                   
                    
                </div>

                <!-- Logisctics Centers -->
                <div class="grid grid-cols-2 gap-4" v-if="!onlyDrivers">
                    <md-field>
                        <validation-provider name='logistics_centers' :rules="''" v-slot="{ errors }" mode="aggressive">
                            <label for="movie">{{ $t('fields.logistics_centers') }}</label>
                            <md-select v-model="selectedCenters" name="logistics_centers" id="logistics_centers" :placeholder="$t('fields.logistics_centers')" multiple :disabled="loading || !editing || !allowedEdit">
                                <md-option v-for="(center, index) in logisticsCenters" :key="index" :value="center.code">
                                    {{ center.name }}
                                </md-option>
                            </md-select>
                            <span class="md-error">{{ errors[0] }}</span>
                        </validation-provider>
                    </md-field>
                </div>

                <div v-if="editing" class="flex justify-end gap-4 mt-4">
                    <button @click.prevent="remember()"
                        class="btn-secondary font-bold py-2 px-4 rounded-xl md:w-60" :disabled="loading || !allowedEdit">
                        {{ $t("reset_pass") }}
                    </button>
                    <button  @click.prevent="showConfirm = true"
                        class="btn-secondary font-bold py-2 px-4 rounded-xl md:w-40" :disabled="loading || !allowedEdit">
                        {{ $t("delete") }}
                    </button>
                    <button type="submit" class="btn-primary font-bold py-2 px-4 rounded-xl" :disabled="loading || !editing || !allowedEdit">
                        {{ $t("save") }}
                    </button>
                </div>
            </form>
        </ValidationObserver>

        <modal v-if="showConfirm" @close="showConfirm = false">
			<h3 slot="header">
				{{ $t("confirm") }}
			</h3>
			<div slot="body">
                <i18n v-if="onlyDrivers" path="confirm_messages.delete_driver" tag="p">
					<template #driver>
						{{ user.email }}
					</template>
				</i18n>
				<i18n v-else path="confirm_messages.delete_user" tag="p">
					<template #user>
						{{ user.email }}
					</template>
				</i18n>
			</div>
			<div slot="footer" class="flex gap-4">
				<button @click="showConfirm = false"
					class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-xl">
					{{ $t("cancel") }}
				</button>
				<button @click="deleteUser(user)"
					class="btn-secondary font-bold py-2 px-4 rounded-xl">
					{{ $t("delete") }}
				</button>
			</div>
		</modal>
    </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import Modal from '@/components/Modal.vue';
import authMixin from "@/mixins/auth";
import { Roles } from '@/config';
import { 
    updateUser, 
    deleteUser, 
    getUserLogisticsCenters, 
    saveUserLogisticsCenters 
} from '@/services/users';
import { getServiceFilters } from "@/services/services";
export default {
    props: ["user", "editing"],
    mixins: [authMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        Modal,
    },

    data() {
        return {
            loading: false,
            showConfirm: false,
            onlyDrivers: false,
            forceProvider: false,
            disableProvider: false,
            touchedFields: {
                email: false,
                name: false,
                family_name: false,
                role: false,
                provider: false,
            },
            email: "",
            name: "",
            family_name: "",
            role: "",
            roles: Roles,
            provider: "",
            providers: [],
            logisticsCenters: [],
            selectedCenters: [],
        };
    },

    watch: {
        role(new_role) {
            if (new_role == Roles.ADMIN) {
                this.provider = "null";
                this.disableProvider = true;
            } else {
                this.provider = this.user.provider.idProvider;
                this.disableProvider = false;
            }
        },
    },

    computed: {
        currentRouteName() {
			return this.$route.name.substr(3);
		},
        allowedEdit: function () {
            if (this.isAdmin) {
                return true;
            }
            if (this.currentUserRole == Roles.PROVIDERADMIN && (this.user.role == Roles.PROVIDERADMIN || this.user.role == Roles.DRIVER)) {
                return true;
            }
            return false;
        },
    },

    created: async function() {
        this.checkPermissions();
        //Redirect on refresh
        if (this.editing == undefined && this.user == undefined) {
            const backRoute = this.currentRouteName == 'user_detail' ? 'users' : 'drivers';
            this.$router.push({ name: this.$i18n.locale + '.' + backRoute });
            return;
        }
        // Disable edit provider for ProviderAdmin
        const currentUserRole = await this.getCurrentUserRole();
        if (currentUserRole == Roles.PROVIDERADMIN) {
            this.forceProvider = true;
        }
        this.loading = true;
        if(this.currentRouteName == "driver_detail") {
            this.onlyDrivers = true;
            this.role = Roles.DRIVER;
        }
        await this.fetchFilters();
        await this.fetchLogisticsCenters();
        this.email = this.user.email;
        this.name = this.user.name;
        this.family_name = this.user.familyName;
        this.provider = this.user.provider.idProvider;
        this.role = this.user.role;
        this.loading = false;
	},

    methods: {
        async save() {
            this.loading = true;
            const userToUpdate = {
                idUser: this.user.idUser,
                name: this.name,
                familyName: this.family_name,
                role: this.role,
                provider: {
                    idProvider: this.provider,
                }
            };
            const response = await updateUser(userToUpdate);
            if (response) {
                // Save logisctis centers for admins and providerAdmins
                if (!this.onlyDrivers) {
                    await saveUserLogisticsCenters(userToUpdate, this.selectedCenters);
                }
                const success_msg = this.onlyDrivers ? this.$t('success.driver_saved') : this.$t('success.user_saved');
                this.$noty.success(success_msg);
            } else {
                this.$noty.error(this.$t('error.saved'));
            }
            this.loading = false;
        },

        async deleteUser(user) {
            this.loading = true;
			await deleteUser(user);
			this.showConfirm = false;
            const success_msg = this.onlyDrivers ? this.$t('success.driver_deleted') : this.$t('success.user_deleted');
			this.$noty.success(success_msg);
            if (this.onlyDrivers) {
                this.$router.push({ name: this.$i18n.locale + '.drivers' });
            } else {
                this.$router.push({ name: this.$i18n.locale + '.users' });
            }
            this.loading = false;
		},

        async remember() {
            this.loading = true;
            try {
                let username = this.user.email;
                await Auth.forgotPassword(username);
                this.$noty.success(this.$t('success.forgot_pass'));
            } catch (err) {
                console.error('Error reseting pass', err);
                var errorMsg = '';
                switch (err.code) {
                    case 'LimitExceededException':
                        errorMsg = this.$t('error.limit_exceeded');
                        break;
                    default:
                        errorMsg = this.$t('error.forgot_pass');
                }
                this.$noty.error(errorMsg);
            }
            this.loading = false;
        },

        async fetchFilters() {
			const serviceFilters = await getServiceFilters();
            this.logisticsCenters = serviceFilters.logisticsCenters;
            this.providers = serviceFilters.providers;
		},

        async fetchLogisticsCenters() {
            const response = await getUserLogisticsCenters(this.user);
            if (response && response.data) {
                this.selectedCenters = response.data;
            }
        },
    },
};
</script>