<template>
    <div class="md-layout text-center">
        <div class="md-layout-item md-size-70 md-medium-size-70 md-small-size-70 md-xsmall-size-100">
            <ValidationObserver v-slot="{ handleSubmit, errors }">
                <form @submit.prevent="handleSubmit(reset)">
                    <login-card>
                        <div slot="title" class="pt-4">
                            <img src="@/assets/logo-prezero-4drivers.svg" alt="Pre Zero 4 Drivers">
                            <!-- <h3 class="pt-4 text-4xl uppercase">
                                4 Drivers
                            </h3> -->
                        </div>
                        
                        <md-field slot="inputs" :class="[
                            { 'md-valid': errors['email'] && !errors['email'][0] },
                            { 'md-error': errors['email'] && errors['email'][0] }
                        ]">
                            <label for="email">{{ $t('fields.email') }}</label>
                            <validation-provider name='email' rules="required|email" v-slot="{ errors }">
                                <md-input v-model="email" name="email" type="email" :placeholder="$t('fields.email')" :disabled="loading"></md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors[0]">close</md-icon>
                                </slide-y-down-transition>
                                <span class="md-error">{{ errors[0] }}</span>
                            </validation-provider>
                        </md-field>
                        <md-field slot="inputs" :class="[
                            { 'md-valid': errors['confirmation_code'] && !errors['confirmation_code'][0] },
                            { 'md-error': errors['confirmation_code'] && errors['confirmation_code'][0] }
                        ]">
                            <label for="confirmation_code">{{ $t('fields.confirmation_code') }}</label>
                            <validation-provider name='confirmation_code' rules="required" v-slot="{ errors }">
                                <md-input v-model="confirmation_code" name="confirmation_code" :placeholder="$t('fields.confirmation_code')" id="confirmation_code" :disabled="loading"></md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors[0] ">close</md-icon>
                                </slide-y-down-transition>
                                <span class="md-error">{{ errors[0] }}</span>
                            </validation-provider>
                        </md-field>
                        <md-field slot="inputs" :class="[
                            { 'md-valid': errors['password'] && !errors['password'][0] },
                            { 'md-error': errors['password'] && errors['password'][0] }
                          ]">
                            <label for="password">{{ $t('fields.password') }}</label>
                            <validation-provider name='password' rules="required" v-slot="{ errors }" mode="aggressive">
                                <md-input v-model="password" name="password" type="password" :placeholder="$t('fields.password')" id="password" :disabled="loading"></md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors[0]">close</md-icon>
                                </slide-y-down-transition>
                                <span class="md-error">{{ errors[0] }}</span>
                            </validation-provider>
                        </md-field>
                        <div class="w-full" slot="footer">
                            <button type="submit" class="w-full btn-primary font-bold py-2 px-4 rounded-lg" id="btnLogin" :disabled="loading">
                                <!-- {{ $t('request_code') }} -->
                                Cambiar contraseña
                            </button>
                            <div class="text-left mt-2">
                                <router-link :to="{ name: $i18n.locale + '.remember_password' }" class="link-underline">
                                    <!-- {{ $t('forgotten_password_message') }} -->
                                    {{ $t('request_code') }}
                                </router-link>
                            </div>
                        </div>
                    </login-card>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import LoginCard from "../../Cards/LoginCard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
export default {
    components: {
        LoginCard,
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition
    },
    data() {
        return {
            loading: false,
            email: "",
            confirmation_code: "",
            password: "",
        };
    },

    methods: {
        async reset() {
            this.loading = true;
            try {
                let username = this.email;
                let code = this.confirmation_code;
                let new_password = this.password;
                await Auth.forgotPasswordSubmit(username, code, new_password);
                this.$noty.success(this.$t('success.forgot_pass_confirm'));
                this.$router.push({ name: this.$i18n.locale + '.login' });
                this.loading = false;
                return true;
            } catch (err) {
                console.error('Error stablishing new pass', err);
                var errorMsg = '';
                switch (err.code) {
                    case 'CodeMismatchException':
                        errorMsg = this.$t('error.code_mismatch');
                        break;
                    case 'ExpiredCodeException':
                        errorMsg = this.$t('error.expired_code');
                        break;
                    case 'LimitExceededException':
                        errorMsg = this.$t('error.limit_exceeded');
                        break;
                    default:
                        errorMsg = this.$t('error.forgot_pass_confirm');
                }
                this.$noty.error(errorMsg);
                this.loading = false;
                return false;
            }
        },
    },
}
</script>