<template>
    <div class="bg-white pb-4 md:px-12">
        <!-- Back button -->
        <div class="flex justify-end">
            <a class="back-button" @click="$router.go(-1)">
                <i class="material-icons">arrow_back</i>
                {{ $t("back") }}
            </a>
        </div>
        <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
            <!-- Code -->
            <div>
                <md-field :class="[
                { 'md-valid': errors['code'] && !errors['code'][0] && touchedFields.code },
                { 'md-error': errors['code'] && errors['code'][0] }
                ]">
                <label for="code">{{ $t('fields.code') }}</label>
                <validation-provider name='code' rules="required" v-slot="{ errors, touched }" mode="aggressive">
                    <md-input v-model="code" name="code" type="text" :placeholder="$t('fields_text.code')" id="code" :disabled="loading || view"></md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
                </md-field>
            </div>

            <!-- Name -->
            <div>
                <md-field :class="[
                { 'md-valid': errors['description'] && !errors['description'][0] && touchedFields.description },
                { 'md-error': errors['description'] && errors['description'][0] }
                ]">
                <label for="name">{{ $t('fields.name') }}</label>
                <validation-provider name='name' rules="required" v-slot="{ errors, touched }" mode="aggressive">
                    <md-input v-model="description" name="description" type="text" :placeholder="$t('fields_text.name')" id="name" :disabled="loading || view"></md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
                </md-field>
            </div>

            <!-- Haulier code -->
            <div>
                <md-field :class="[
                { 'md-valid': errors['prefix'] && !errors['prefix'][0] && touchedFields.prefix },
                { 'md-error': errors['prefix'] && errors['prefix'][0] }
                ]">
                <label for="prefix">{{ $t('fields.prefix') }}</label>
                <validation-provider name='prefix' rules="required" v-slot="{ errors, touched }" mode="aggressive">
                    <md-input v-model="prefix" name="prefix" type="text" :placeholder="$t('fields_text.prefix')" id="prefix" :disabled="loading || view"></md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
                </md-field>
            </div>

            <div v-if="!view" class="flex justify-end gap-4 mt-4">
                <button v-show="editing || view" @click.prevent="showConfirm = true"
                    class="btn-secondary font-bold py-2 px-4 rounded-xl md:w-40" :disabled="loading">
                    {{ $t("delete") }}
                </button>
                <button @click.prevent="save()" type="submit" class="btn-primary font-bold py-2 px-4 rounded-xl" :disabled="loading || view">
                    {{ $t("save") }}
                </button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import authMixin from "@/mixins/auth";
import { createLogisticsCenter, updateLogisticsCenter } from "@/services/logistics_centers";

export default {
    props: ["center", "creating", "editing", "view"],
    mixins: [authMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
    },

    data() {
        return {
            loading: false,
            showConfirm: false,
            touchedFields: {
                code: false,
                description: false,
                prefix: false,
            },
            idCenter: null,
            code: "",
            description: "",
            prefix: "",
        };
    },

    created: function () {
        this.checkPermissions();
        // Redirect to logistics center without mode props
        if (this.creating == undefined && this.editing == undefined && this.view == undefined) {
            this.$router.push({ name: this.$i18n.locale + '.logistics_centers' });
        }
        if (this.center) {
            this.idCenter = this.center.idCenter,
            this.code = this.center.code;
            this.description = this.center.name;
            this.prefix = this.center.prefix;
        }
    },

    methods: {
        async save () {
            this.loading = true;
            var valid = await this.$refs.form.validate();
            if (valid) {
                const centerToSave = {
                    idCenter: this.idCenter,
                    code: this.code,
                    name: this.description,
                    prefix: this.prefix,
                };

                if (this.creating) {
                    const response = await createLogisticsCenter(centerToSave);
                    if (response) {
                        this.$noty.success(this.$t('success.logistics_center_saved'));
                        this.clearForm();
                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    } 
                } else if (this.editing) {
                    const response = await updateLogisticsCenter(centerToSave);
                    if (response) {
                        this.$noty.success(this.$t('success.logistics_center_saved'));
                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                }
            }
            this.loading = false;
        },

        clearForm() {
            this.code = "";
            this.description = "";
            this.prefix = "";
            this.touchedFields = {
                code: false,
                description: false,
                prefix: false,
            };
            this.$refs.form.reset();
        },
    },
}
</script>