import { API, Auth } from 'aws-amplify';
import util from 'util';

/**
 * Get vehicles list
 *
 * @param {string} filter - Filter string
 * @param {boolean} motorizedFilter - Motorized Filter
 * @param {boolean} activeFilter - Active Filter
 * @param {string} providerFilter - Provider Filter string
 * @returns {Promise} Promise - object with the result of the api call response
 */
 const getVehicles = async (page= 1, filter = null, motorizedFilter = null, activeFilter = null, providerFilter = null) => {
    let responseData = {};
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/vehicle'];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const queryStringParameters = {
        page: page,
        filter: filter,
        motorized: motorizedFilter,
        active: activeFilter,
        provider: providerFilter,
    };
    const myInit = {
        headers: headers,
        response: true,
        queryStringParameters: queryStringParameters,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        responseData = response.data;
    } catch (error) {
        console.error(
            "Error getting vehicles: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return responseData;
}

/**
 * Get provider vehicles list
 *
 * @param {uuid} idProvider - Provider UUID
 * @param {string} filter - Filter string
 * @param {boolean} motorizedFilter - Motorized Filter
 * @param {boolean} activeFilter - Active Filter
 * @returns {Promise} Promise - object with the result of the api call response
 */
 const getProviderVehicles = async (idProvider, page = 1, filter = null, motorizedFilter = null, activeFilter = null) => {
    let vehicles = [];
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/provider/' + idProvider + '/vehicle'];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const queryStringParameters = {
        page: page,
        filter: filter,
        motorized: motorizedFilter,
        active: activeFilter,
    };
    const myInit = {
        headers: headers,
        response: true,
        queryStringParameters: queryStringParameters,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        vehicles = response.data;
    } catch (error) {
        console.error(
            "Error getting vehicles: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return vehicles;
}

/**
 * Create vehicle
 *
 * @param {Object} vehicle - vehicle object
 * @returns {Promise} Promise - object with the result of the api call response
 */
 const createVehicle = async (vehicle) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/provider/' + vehicle.provider.idProvider + '/vehicle'];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: vehicle,
    };
    try {
        var response = await API.post(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error creating vehicle: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
}

/**
 * Update vehicle
 *
 * @param {uuid} idProvider - UUID vehicle provider
 * @param {Object} vehicle - vehicle object
 * @returns {Promise} Promise - object with the result of the api call response
 */
 const updateVehicle = async (idProvider, vehicle) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/provider/' + idProvider + '/vehicle/' + vehicle.idVehicle];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: vehicle,
    };
    try {
        var response = await API.put(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error updating vehicle: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
}

/**
 * Delete vehicle
 *
 * @param {Object} vehicle - vehicle object
 * @returns {Promise} Promise - object with the result of the api call response
 */
 const deleteVehicle = async (vehicle) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/provider/' + vehicle.provider.idProvider + '/vehicle/' + vehicle.idVehicle];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.del(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error deleting vehicle: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
}

export {
    getVehicles,
    getProviderVehicles,
    createVehicle,
    updateVehicle,
    deleteVehicle,
}