<template>
    <div class="wrapper" :class="[
        { 'nav-open': $sidebar.showSidebar },
        { rtl: $route.meta.rtlActive }
    ]">
        <notifications></notifications>
        <side-bar :active-color="sidebarBackground" :background-image="sidebarBackgroundImage" :data-background-color="sidebarBackgroundColor">
            <!-- <user-menu></user-menu> -->
            <mobile-menu></mobile-menu>
            <template slot="links">
                <sidebar-item v-if="$route.meta.rtlActive" :link="{ name: '', icon: 'dashboard' }">
                </sidebar-item>
                <!-- <sidebar-item v-else :link="getRouteLink('dashboard', 'dashboard')">
                </sidebar-item> -->
                <sidebar-item v-if="$route.meta.rtlActive" :link="{ name: '', icon: 'image' }">
                    <sidebar-item :link="getRouteLink('login')"></sidebar-item>
                    <sidebar-item :link="getRouteLink('register')"></sidebar-item>
                    <sidebar-item :link="getRouteLink('profile')"></sidebar-item>
                </sidebar-item>
                <!-- <sidebar-item v-else :link="{ name: 'Pages', icon: 'image' }">
                    <sidebar-item :link="getRouteLink('login')"></sidebar-item>
                    <sidebar-item :link="getRouteLink('register')"></sidebar-item>
                    <sidebar-item :link="getRouteLink('profile')"></sidebar-item>
                </sidebar-item> -->
                <sidebar-item :link="getRouteLink('services', 'icons_menu_services')">
                </sidebar-item>
                <sidebar-item :link="getRouteLink('drivers', 'icons_menu_driver')">
                </sidebar-item>
                <sidebar-item v-if="isAdmin" :link="getRouteLink('logistics_centers', 'icons_menu_logistics_centers')">
                </sidebar-item>
                <sidebar-item v-if="isAdmin" :link="getRouteLink('providers', 'icons_menu_provider')">
                </sidebar-item>
                <sidebar-item v-if="isAdmin" :link="getRouteLink('synchronization', 'icons_menu_sync')">
                </sidebar-item>
                <sidebar-item v-if="isAdmin" :link="getRouteLink('users', 'icons_menu_users')">
                </sidebar-item>
                <sidebar-item :link="getRouteLink('vehicles', 'icons_menu_vehicles')">
                </sidebar-item>
            </template>
        </side-bar>
        <div class="main-panel">
            <top-navbar></top-navbar>
            <!-- <fixed-plugin :color.sync="sidebarBackground" :colorBg.sync="sidebarBackgroundColor" :sidebarMini.sync="sidebarMini" :sidebarImg.sync="sidebarImg" :image.sync="sidebarBackgroundImage">
            </fixed-plugin> -->
            <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
                <zoom-center-transition :duration="200" mode="out-in">
                    <!-- your content here -->
                    <router-view></router-view>
                </zoom-center-transition>
            </div>
            <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
        </div>
    </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import authMixin from "@/mixins/auth";

function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
    if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

import TopNavbar from "./TopNavbar.vue";
//import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./MobileMenu.vue";
//import FixedPlugin from "../FixedPlugin.vue";
// import UserMenu from "./UserMenu.vue";
import { ZoomCenterTransition } from "vue2-transitions";
import linkMixin from "../../../mixins/link";

export default {
    mixins: [linkMixin, authMixin],
    components: {
        TopNavbar,
        // ContentFooter,
        MobileMenu,
        //FixedPlugin,
        // UserMenu,
        ZoomCenterTransition
    },
    data() {
        return {
            sidebarBackgroundColor: "white",
            sidebarBackground: "azure",
            sidebarBackgroundImage: "/img/sidebar-2.jpg",
            sidebarMini: true,
            sidebarImg: true
        };
    },
    methods: {
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        },
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        },
        getRouteLink(route, icon) {
            let routeLink = {
                name: this.$t('routes.' + route + '.title'),
                path: this.getRouteHref(route)
            }

            if (icon) {
                routeLink.icon = icon;
            }

            return routeLink;
        }
    },
    mounted() {
        let docClasses = document.body.classList;
        let isWindows = navigator.platform.startsWith("Win");
        if (isWindows) {
            // if we are on windows OS we activate the perfectScrollbar function
            initScrollbar("sidebar");
            initScrollbar("sidebar-wrapper");
            initScrollbar("main-panel");

            docClasses.add("perfect-scrollbar-on");
        } else {
            docClasses.add("perfect-scrollbar-off");
        }
    },
    watch: {
        sidebarMini() {
            this.minimizeSidebar();
        }
    }
};
</script>
<style lang="scss">
$scaleSize: 0.95;

@keyframes zoomIn95 {
    from {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }

    to {
        opacity: 1;
    }
}

.main-panel .zoomIn {
    animation-name: zoomIn95;
}

@keyframes zoomOut95 {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
}

.main-panel .zoomOut {
    animation-name: zoomOut95;
}
</style>