<template>
    <div class="bg-white pt-6 pb-12 md:px-12">
        <!-- Back button -->
        <div class="flex justify-end">
            <a class="back-button" @click="$router.go(-1)">
                <i class="material-icons">arrow_back</i>
                {{ $t("back") }}
            </a>
        </div>
        <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
            <!-- Name & Family Name -->
            <div class="grid grid-cols-2 gap-4">
                <md-field :class="[
                { 'md-valid': errors['name'] && !errors['name'][0] && touchedFields.name },
                { 'md-error': errors['name'] && errors['name'][0] }
                ]">
                <label for="name">{{ $t('fields.name') }}</label>
                <validation-provider name='name' rules="required" v-slot="{ errors, touched }" mode="aggressive">
                    <md-input v-model="name" name="name" type="text" :placeholder="$t('fields_text.name')" id="name" :disabled="loading"></md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
                </md-field>

                <md-field :class="[
                    { 'md-valid': errors['family_name'] && !errors['family_name'][0] && touchedFields.family_name },
                    { 'md-error': errors['family_name'] && errors['family_name'][0] }
                    ]">
                    <label for="name">{{ $t('fields.family_name') }}</label>
                    <validation-provider name='family_name' rules="required" v-slot="{ errors, touched }" mode="aggressive">
                        <md-input v-model="family_name" name="family_name" type="text" :placeholder="$t('fields_text.family_name')" id="family_name" :disabled="loading"></md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <!-- Email -->
            <div>
                <md-field :class="[
                    { 'md-valid': errors['email'] && !errors['email'][0] && touchedFields.email },
                    { 'md-error': errors['email'] && errors['email'][0] }
                    ]">
                    <label for="email">{{ $t('fields.email') }}</label>
                    <validation-provider name='email' rules="required|email" v-slot="{ errors, touched }" mode="aggressive">
                        <md-input v-model="email" name="email" type="email" :placeholder="$t('fields_text.email')" id="email" :disabled="loading"></md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <!-- Password -->
            <div>
                <md-field slot="inputs" :class="[
                    { 'md-valid': errors['password'] && !errors['password'][0] && touchedFields.password },
                    { 'md-error': errors['password'] && errors['password'][0] }
                    ]">
                    <label for="password">{{ $t('fields.password') }}</label>
                    <validation-provider name="password" rules="required|min:8|max:15|complex_password" ref="password" v-slot="{ errors, touched }" mode="aggressive">
                        <md-input v-model="password" name="password" type="password" :placeholder="$t('fields.password')" id="password" :disabled="loading"></md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <!-- Confirm Password -->
            <div>
                <md-field slot="inputs" :class="[
                    { 'md-valid': errors['c_password'] && !errors['c_password'][0] && touchedFields.c_password },
                    { 'md-error': errors['c_password'] && errors['c_password'][0] }
                    ]">
                    <label for="password">{{ $t('fields.password_confirm') }}</label>
                    <validation-provider name='password_confirm' rules="required|confirmed:password" v-slot="{ errors, touched }" mode="aggressive">
                        <md-input v-model="c_password" name="password_confirm" type="password" :placeholder="$t('fields.password_confirm')" id="c_password" :disabled="loading"></md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <!-- Provider & Role -->
            <div class="grid grid-cols-2 gap-4">
                                
                <md-field v-if="!onlyDrivers" slot="inputs" :class="[
                    { 'md-valid': errors['role'] && !errors['role'][0] && touchedFields.role },
                    { 'md-error': errors['role'] && errors['role'][0] }
                    ]">
                    <validation-provider name='role' rules="required" v-slot="{ errors }" mode="aggressive">
                        <label for="movie">{{ $t('fields.role') }}</label>
                        <md-select v-model="role" name="role" id="role" :placeholder="$t('fields.role')">
                            <md-option v-for="(role, index) in roles" :key="index" :value="role">
                                {{ $t('roles.' + role) }}
                            </md-option>
                        </md-select>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>

                <md-field slot="inputs" :class="[
                    { 'md-valid': errors['provider'] && !errors['provider'][0] && touchedFields.provider },
                    { 'md-error': errors['provider'] && errors['provider'][0] }
                    ]">
                    <validation-provider name='provider' rules="required" v-slot="{ errors }" mode="aggressive">
                        <label for="movie">{{ $t('fields.provider') }}</label>
                        <md-select v-model="provider" name="provider" id="provider" :placeholder="$t('fields.provider')" :disabled="forceProvider || disableProvider">
                            <md-option v-for="(provider, index) in providers" :key="index" :value="provider.idProvider">
                                {{ provider.name }}
                            </md-option>
                        </md-select>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
                
            </div>

            <!-- Logisctics Centers -->
            <div class="grid grid-cols-2 gap-4">
                    <md-field>
                        <validation-provider name='logistics_centers' :rules="''" v-slot="{ errors }" mode="aggressive">
                            <label for="movie">{{ $t('fields.logistics_centers') }}</label>
                            <md-select v-model="selectedCenters" name="logistics_centers" id="logistics_centers" :placeholder="$t('fields.logistics_centers')" multiple :disabled="loading">
                                <md-option v-for="(center, index) in logisticsCenters" :key="index" :value="center.code">
                                    {{ center.name }}
                                </md-option>
                            </md-select>
                            <span class="md-error">{{ errors[0] }}</span>
                        </validation-provider>
                    </md-field>
                </div>

            <button @click.prevent="save()" type="submit" class="float-right btn-primary font-bold py-2 px-4 rounded-xl" :disabled="loading">
                {{ $t("save") }}
            </button>
        </ValidationObserver>
    </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import authMixin from "@/mixins/auth";
import { Roles } from '@/config';
import { getServiceFilters } from "@/services/services";
import { saveUserLogisticsCenters, createUser } from '@/services/users';
export default {
    mixins: [authMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
    },

    data() {
        return {
            loading: false,
            onlyDrivers: false,
            forceProvider: false,
            touchedFields: {
                email: false,
                password: false,
                c_password: false,
                name: false,
                family_name: false,
                role: false,
                provider: false,
            },
            email: "",
            password: "",
            c_password: "",
            name: "",
            family_name: "",
            role: "",
            provider: "",
            providers: [],
            logisticsCenters: [],
            selectedCenters: [],
            disableProvider: false,
        }
    },

    watch: {
        role(new_role) {
            if (new_role == Roles.ADMIN) {
                this.provider = "null";
                this.disableProvider = true;
            } else {
                this.provider = "";
                this.disableProvider = false;
            }
        },
    },

    computed: {
        currentRouteName() {
			return this.$route.name.substr(3);
		},
        roles: function() {
            if (this.isAdmin) {
                return Roles;
            }
            if (this.currentUserRole == Roles.PROVIDERADMIN) {
                return {
                    PROVIDERADMIN: 'providerAdmin',
                    DRIVER: 'driver',
                }
            }
            return [];
        },
    },

    created: async function() {
        this.checkPermissions();
        this.loading = true;
        if(this.currentRouteName == "driver_create") {
            this.onlyDrivers = true;
            this.role = Roles.DRIVER;
        }
        await this.fetchFilters();
        // Disable select provider for ProviderAdmin
        const currentUserRole = await this.getCurrentUserRole();
        if (currentUserRole == Roles.PROVIDERADMIN) {
            this.forceProvider = true;
            const currentUserProvider = await this.getCurrentUserProvider();
            this.provider = currentUserProvider;
        }
        this.loading = false;
	},

    methods: {
        async save() {
            this.loading = true;
            var valid = await this.$refs.form.validate();
            if (valid) {
                try {
                    // const response = await this.signUpUser();
                    const userSub = await this.createUserLambda();

                    // TODO cambiar a response.data con llamada a lambda
                    if (userSub) {
                        // Save logisctis centers for admins and providerAdmins
                        if (!this.onlyDrivers) {
                            const createdUser = {
                                idUser: userSub,
                            }
                            await saveUserLogisticsCenters(createdUser, this.selectedCenters);
                        }
                        const success_msg = this.onlyDrivers ? this.$t('success.driver_saved') : this.$t('success.user_saved');
                        this.$noty.success(success_msg);
                        this.clearForm();
                    }
                } catch (error) {
                    console.log('error creating user:', error);
                    if (error.message.includes('An account with the given email already exists.')) {
                        this.$noty.error(this.$t('error.username_exists'));
                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                }
            }
            this.loading = false;
        },

        async signUpUser() {
            const response = await Auth.signUp({
                username: this.email,
                password: this.password,
                attributes: {
                    email: this.email,
                    name: this.name,
                    family_name: this.family_name,
                    'custom:provider': this.provider,
                    'custom:role': this.role,                        
                }
            });
            if (response && response.userSub) {
                return response.userSub;
            } else {
                return false;
            }
        },

        async createUserLambda() {
            const user = this.getUserObject();
            const response = await createUser(user);

            if (response && response.data && response.data.userSub) {
                return response.data.userSub;
            } else {
                return false;
            }
        },

        clearForm() {
            this.name = "";
            this.family_name = "";
            this.password = "";
            this.c_password = "";
            this.email = "";
            this.provider = "";
            this.role = "";
            this.touchedFields = {
                email: false,
                password: false,
                c_password: false,
                name: false,
                family_name: false,
                role: false,
                provider: false,
            };
            this.selectedCenters = [];
            this.$refs.form.reset();
        },

        async fetchFilters() {
			const serviceFilters = await getServiceFilters();
            this.logisticsCenters = serviceFilters.logisticsCenters;
            this.providers = serviceFilters.providers;
		},

        getUserObject() {
            const user = {
                email: this.email,
                password: this.password,
                name: this.name,
                familyName: this.family_name,
                role: this.role,
                provider: {
                    idProvider: this.provider,
                }
            };
            return user;
        }
    }
}
</script>