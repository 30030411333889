var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bg-white pt-6 md:px-12 pb-3"},[_c('router-link',{staticClass:"btn-add float-right",attrs:{"to":{ name: _vm.$i18n.locale + '.provider_form', params: { creating: true } },"tag":"button"}},[_vm._v(" "+_vm._s(_vm.$t("add"))+" ")]),_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t("enter_text")))]),_c('md-input',{attrs:{"maxlength":"50"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"flex justify-end items-center gap-10"},[_c('div',{staticClass:"grow flex gap-2"},[(_vm.loading)?_c('md-progress-spinner',{attrs:{"md-diameter":30,"md-stroke":3,"md-mode":"indeterminate"}}):_vm._e(),_c('button',{staticClass:"btn-secondary font-bold py-2 px-4 rounded-xl md:w-40",on:{"click":function($event){return _vm.clearFilter()}}},[_vm._v(" "+_vm._s(_vm.$t("delete_filter"))+" ")]),_c('button',{staticClass:"btn-primary font-bold py-2 px-4 rounded-xl",on:{"click":function($event){return _vm.filter()}}},[_vm._v(" "+_vm._s(_vm.$t("search"))+" ")])],1)])],1),_c('div',{staticClass:"pt-6 md:px-12"},[_c('table',{staticClass:"table-rows-rounded min-w-full"},[_c('thead',{staticClass:"underline text-center"},[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.$t("name"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("identifier"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("haulier"))+" ")]),_c('th')])]),_c('tbody',{staticClass:"text-center"},_vm._l((_vm.providers),function(provider,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(provider.name)+" ")]),_c('td',[_vm._v(_vm._s(provider.idCLEAR))]),_c('td',[_vm._v(_vm._s(provider.haulierCode))]),_c('td',{staticClass:"text-right"},[_c('div',{staticClass:"table-crud-buttons"},[_c('router-link',{attrs:{"tag":"button","to":{ 
									name: _vm.$i18n.locale + '.provider_form',
										params: {
											provider: provider,
											view: true,
										},
									}}},[_c('img',{attrs:{"src":require('@/assets/icons/icons_actions_view.svg')}})]),_c('router-link',{attrs:{"tag":"button","to":{ 
									name: _vm.$i18n.locale + '.provider_form',
										params: {
											provider: provider,
											editing: true,
										},
									}}},[_c('img',{attrs:{"src":require('@/assets/icons/icons_actions_edit.svg')}})]),_c('button',{on:{"click":function($event){return _vm.confirmDelete(provider)}}},[_c('img',{attrs:{"src":require('@/assets/icons/icons_actions_delete.svg')}})])],1)])])}),0)]),_c('div',{staticClass:"flex justify-center pb-6"},[_c('pagination',{attrs:{"noArrows":true,"perPage":_vm.perPage,"total":_vm.num_providers},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]),(_vm.showConfirm)?_c('modal',{on:{"close":function($event){_vm.showConfirm = false}}},[_c('h3',{attrs:{"slot":"header"},slot:"header"},[_vm._v(" "+_vm._s(_vm.$t("confirm"))+" ")]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('i18n',{attrs:{"path":"confirm_messages.delete_provider","tag":"p"},scopedSlots:_vm._u([{key:"provider",fn:function(){return [_vm._v(" "+_vm._s(_vm.providerToDelete.name)+" ")]},proxy:true}],null,false,502866011)})],1),_c('div',{staticClass:"flex gap-4",attrs:{"slot":"footer"},slot:"footer"},[_c('button',{staticClass:"bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-xl",on:{"click":function($event){_vm.showConfirm = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('button',{staticClass:"btn-secondary font-bold py-2 px-4 rounded-xl",on:{"click":function($event){return _vm.deleteProvider(_vm.providerToDelete)}}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }