<template>
	<div>
		<!-- Toolbar -->
		<div class="bg-white pt-6 md:px-12">
			<div class="grid grid-cols-2 items-center gap-10">
				<!--
				<md-field>
					<label for="movie">{{ $t('fields.provider') }}</label>
					<md-select v-model="provider" name="provider" id="provider" :placeholder="$t('fields.provider')">
						<md-option :value="'all'">
							{{ $t("all") }}
						</md-option>
						<md-option v-for="(provider, index) in providers" :key="index" :value="provider.idProvider">
							{{ provider.name }}
						</md-option>
					</md-select>
				</md-field>
				-->
				<md-autocomplete v-model="providerName" :md-options="providersNames" :md-fuzzy-search="false">
					<label>{{ $t('fields.provider') }}</label>
				</md-autocomplete>
				<!-- Search buttons -->
				<div class="flex justify-end items-center gap-4">
					<md-progress-spinner v-if="loading || synchronizing" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
					<button @click="showConfirmModal" class="btn-primary w-56 font-bold py-2 px-4 rounded-xl" :disabled="loading || synchronizing">
						<span v-if="!synchronizing">
							{{ $t("sync_up") }}
						</span>
						<span v-else>
							{{ $t("synchronizing") }}
						</span>
					</button>
				</div>
			</div>
		</div>
		<!-- Table -->
		<div class="pt-6 md:px-12">
			<table class="table-rows-rounded min-w-full">
				<thead class="underline text-center">
					<tr>
						<th>
							{{ $t("sync_date") }}
						</th>
						<th>
							{{ $t("name") }}
						</th>
						<th>
							{{ $t("email") }}
						</th>
						<th>
							<!-- Status -->
						</th>
					</tr>
				</thead>
				<tbody class="text-center">
					<tr v-for="(synchronization, index) in synchronizations" :key="index">
						<td>
							{{ synchronization.date | formatDate }}
						</td>
						<td>
							{{ synchronization.name }}
						</td>
						<td>
							{{ synchronization.email }}
						</td>
						<td>
							<span v-if="synchronization.status == 'success'" class="badge badge-success w-full">
								{{ $t('success.success') }}
							</span>
							<span v-else class="badge badge-danger cursor-pointer w-full" @click="showDetail(synchronization.detail)">
								{{ $t('error.error') }}
							</span>
						</td>
					</tr>
				</tbody>
			</table>
			<!-- Pagination -->
            <div class="flex justify-center pb-6">
                <pagination v-model="page" :noArrows="true" :perPage="perPage" :total="num_synchronizations"/>
            </div>
		</div>
		<!-- Modals -->
		<modal v-if="showConfirm" @close="showConfirm = false">
			<h3 slot="header">
				{{ $t("confirm") }}
			</h3>
			<div slot="body">
				{{ $t("confirm_messages.sync_up") }}
			</div>
			<div slot="footer" class="flex gap-4">
				<button @click="showConfirm = false"
					class="btn-secondary font-bold py-2 px-4 rounded-xl">
					{{ $t("cancel") }}
				</button>
				<button @click="syncProvider()" :disabled="synchronizing"
					class="btn-primary font-bold py-2 px-4 rounded-xl">
					{{ $t("sync_up") }}
				</button>
			</div>
		</modal>
		<modal v-if="showSyncDetail" @close="showSyncDetail = false" class="modal-errors">
			<h3 slot="header">
				{{ $t("error.error") }}
			</h3>
			<div slot="body" class="errors-container">
				{{ this.detail }}
			</div>
			<div slot="footer" class="flex gap-4">
				<button @click="showSyncDetail = false"
					class="btn-secondary font-bold py-2 px-4 rounded-xl">
					{{ $t("back") }}
				</button>
			</div>
		</modal>
	</div>
</template>
<script>
import Modal from '@/components/Modal.vue';
import authMixin from "@/mixins/auth";
import paginationMixin from "@/mixins/pagination";
import { getServiceFilters } from "@/services/services";
import { syncProvider, getSyncLogs } from '@/services/sync';
import { ForbiddenSyncHours, syncAllCode } from "@/config";
export default {
	mixins: [authMixin, paginationMixin],
	components: {
		Modal,
	},
	data() {
		return {
			loading: false,
			synchronizing: false,
			showConfirm: false,
			showSyncDetail: false,
			detail: "",
			num_synchronizations: 0,
			synchronizations: [],
			providerName: "",
			providers: [],
			providersNames: [],
		};
	},

	watch: {
		page() {
			this.fetchSynchronizations();
		},
	},

	created: async function() {
		this.checkPermissions();
		this.loading = true;
		await this.fetchProviders();
		await this.fetchSynchronizations();
		this.loading = false;
	},

	methods: {
		async syncProvider() {
			this.synchronizing = true;
			this.showConfirm = false;
			let provider;
			// If enter code sync all providers
			if (this.providerName == syncAllCode) {
				provider = {
					idProvider: "all",
				};
			} else {
				provider = this.providers.find(provider => provider.name == this.providerName);
			}
			if (provider) {
				const response = await syncProvider(provider.idProvider);
				if (response.status == 200 || response.status == 204) {
					this.$noty.success(this.$t('success.sync_up'));
				} else if (response.status == 504) {
					this.$noty.warning(this.$t('warning.sync_timeout'));
				} else {
					this.$noty.error(this.$t('error.sync_up'));
				}
				this.fetchSynchronizations();
			} else {
				this.$noty.warning(this.$t('warning.provider_not_found'));
			}
			this.showConfirm = false;
			this.synchronizing = false;
		},

		async fetchProviders() {
			const serviceFilters = await getServiceFilters();
            this.providers = serviceFilters.providers;
			this.providersNames = serviceFilters.providers.map(p => p.name);
		},

		async fetchSynchronizations() {
			this.loading = true;
			const response = await getSyncLogs(this.page);
			this.num_synchronizations = response.numSyncLogs;
			this.synchronizations = response.syncLogs;
			this.loading = false;
		},

		showDetail(detail) {
			this.showSyncDetail = true;
			this.detail = detail;
		},

		showConfirmModal() {
			var isForbidden = false;
			if (this.providerName != syncAllCode) {
				ForbiddenSyncHours.map(period => {
					const isCurrentTimeBetween = this.isCurrentTimeBetween(period.startTime, period.endTime);
					if (isCurrentTimeBetween) {
						isForbidden = isCurrentTimeBetween;
						return;
					}
				});
			}
			if (isForbidden) {
				this.$noty.warning(this.$t("warning.forbidden_sync_up"));
			} else {
				this.showConfirm = true;
			}
		},

		isCurrentTimeBetween(startTime, endTime) {
			const currentTime = this.moment(); // Get current time using Moment.js
			const start = this.moment(startTime, "HH:mm"); // Parse the start time
			const end = this.moment(endTime, "HH:mm"); // Parse the end time

			// If the period doesn't cross midnight
			if (start.isBefore(end)) {
				return currentTime.isBetween(start, end);
			} 
			// If the period spans midnight
			else {
				return currentTime.isAfter(start) || currentTime.isBefore(end);
			}
		},
	},
}
</script>

<style scoped>
.errors-container {
	padding-bottom: 15px;
	overflow: scroll;
}
</style>