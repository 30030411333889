<template>
    <modal class="modal-assign-user" @close="closeModal()">
        <h3 slot="header" class="modal-title">
            {{ $t("assign_driver") }}
        </h3>
        <div slot="body">
            <div>
                <md-field>
                    <label>{{ $t("enter_text") }}</label>
                    <md-input v-model="search" maxlength="50"></md-input>
                </md-field>
            </div>
            <div class="flex gap-4 justify-end items-center">
                <button
                        @click="clearFilter()"
                        class="btn-secondary font-bold py-2 px-4 rounded-xl md:w-40"
                    >
                        {{ $t("delete_filter") }}
                    </button>
                <button
                    @click="filter()"
                    class="btn-primary font-bold py-2 px-4 rounded-xl"
                >
                    {{ $t("search") }}
                </button>
            </div>
            <div>
                <DriverList v-model="user_selected" :users="users" />
            </div>
        </div>
        <div slot="footer" class="flex justify-end items-center gap-4">
            <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
            <button @click="closeModal()"
                class="btn-secondary font-bold py-2 px-4 rounded-xl">
                {{ $t("cancel") }}
            </button>
            <button @click="assignUser()" class="btn-primary font-bold py-2 px-4 rounded-xl">
                {{ $t("assign_driver") }}
            </button>
        </div>
    </modal>
</template>

<script>
import { getUsers } from '@/services/users';
import { assignServicesToUser } from '@/services/services';
import Modal from '@/components/Modal.vue';
import DriverList from "@/components/pages/Services/Modals/DriverList.vue";
import authMixin from "@/mixins/auth";
import { Roles } from '@/config';
export default {
    props: ["services"],
    mixins: [authMixin],
    components: {
		Modal,
        DriverList,
	},
    data() {
        return {
            loading: false,
            filtered: false,
            search: "",
            user_selected: null,
            users: [],
        };
    },

    computed: {
        servicesUUIDs() {
            let servicesUUIDs = [];
            for (const service of this.services) {
                servicesUUIDs.push(service.idService);
            }
            return servicesUUIDs;
        },
    },

    created: async function () {
        await this.fetchUsers();
    },

    methods: {
        closeModal() {
            this.$emit('close');
        },

        async filter() {
            if (this.filtered) {
                await this.fetchUsers();
            }
            this.filtered = true;
            const searchString = this.search.toLowerCase();
            let filtered_users = this.users.filter(function (user) {
                const name = user.name.toLowerCase();
				const familyName = user.familyName.toLowerCase();
				const email = user.email.toLowerCase();
				if (name.includes(searchString) || familyName.includes(searchString) || email.includes(searchString)) {
                    return user;
                }
			});
			this.users = filtered_users;
        },

        async clearFilter() {
            this.filtered = false;
            this.search = "";
            await this.fetchUsers();
        },

        async fetchUsers() {
            this.loading = true;
            this.users = await getUsers();
            let driver_users = this.users.filter(function (user) {
					return user.role == "driver";
				});
            // Check user role & filter drivers with own provider (ProviderAdmin) or service provider (Admin)
            const currentUserRole = await this.getCurrentUserRole();
            let filterIdProvider;
            if (currentUserRole == Roles.ADMIN) {
                filterIdProvider = this.services[0].provider.idProvider;
            } else if (currentUserRole == Roles.PROVIDERADMIN) {
                filterIdProvider = await this.getCurrentUserProvider();
            }
            driver_users = driver_users.filter(function (user) {
                return user.provider.idProvider == filterIdProvider;
            });
            this.users = driver_users;
            this.loading = false;
        },

        async assignUser() {
            this.loading = true;
            if (!this.user_selected) {
                this.$noty.warning(this.$t("warning.no_driver"));
                this.loading = false;
                return;
            }
            const response = await assignServicesToUser(this.user_selected, this.servicesUUIDs);
            if (response) {
                this.$noty.success(this.$t('success.assign_user'));
                this.$emit('close');
            } else {
                this.$noty.error(this.$t('error.saved'));
            }
            this.loading = false;
        },
    },
}
</script>