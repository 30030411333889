<template>
    <table class="table-assign-modal mt-4">
        <thead class="underline text-main-color">
            <tr>
                <th></th>
                <th class="text-left">
                    {{ $t("license_plate") }}
                </th>
                <!-- <th class="text-right">
                    {{ $t("motor") }}
                </th> -->
            </tr>
        </thead>
        <tbody>
            <tr v-for="(vehicle, index) in vehicles" :key="index">
                <td>
                    <md-checkbox v-model="vehicle_selected" @change="handleInput" :value="vehicle.idVehicle"></md-checkbox>
                </td>
                <td class="text-left">
                    {{ vehicle.licensePlate }}
                </td>
                <!-- <td class="text-right">
                    {{ vehicle.motorized ? $t("yes") : $t("no") }}
                </td> -->
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
        },
        vehicles: {
            type: Array,
        },
    },

    data () {
        return {
            vehicle_selected: this.value,
        }
    },

    methods: {
        handleInput(idVehicle) {
            this.vehicle_selected = idVehicle;
            this.$emit('input', this.vehicle_selected);
        }
    },
};
</script>