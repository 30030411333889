export const Locales = Object.freeze({
    ESPANOL: 'es',
    INGLES: 'en'
});

export const Roles = Object.freeze({
    ADMIN: 'admin',
    PROVIDERADMIN: 'providerAdmin',
    DRIVER: 'driver',
});

export const SUPPORTED_LOCALES = [
{
    id: 50,
    code: 'es',
    base: '',
    flag: 'es',
    name: 'Castellano',
    translations: '/locales/es.json'
}, {
    id: 30,
    code: 'en',
    base: '/en',
    flag: 'en',
    name: 'Inglés',
    translations: '/locales/en.json'
}/*, {
    id: 65,
    code: 'eu',
    base: '/eu',
    flag: 'eu',
    name: 'Euskera',
    translations: '/locales/eu.json'
}/*, {
    id: 16,
    code: 'ca',
    base: '/ca',
    flag: 'ca',
    name: 'Catalán',
    translations: '/locales/ca.json'
}, {
    id:76,
    code: 'ga',
    base: '/ga',
    flag: 'ga',
    name: 'Gallego',
    translations: '/locales/ga.json'
}*/
]

export const DEFAULT_TIMEZONE = "Europe/Madrid";

export const Cookies_Info = [
    {
        name: 'CognitoIdentityId',
        duration: '24h',
        description_i18n: 'cookies_info.cognito'
    },
];

export const ServiceStatus = Object.freeze({
    FAILED: "failed",
    FINISHED: "finished",
    INPROGRESS: "inProgress",
    PAUSED: "paused",
    PENDING: "pending",
    ERRORCLEAR: "errorCLEAR",
});

export const ForbiddenSyncHours = [
    {
        startTime: "13:55",
        endTime: "14:30",
    },
];

export const syncAllCode = "WTbvcLgfklA8";

export const googleMaps = Object.freeze({
    url: "http://www.google.com/maps/place/",
});