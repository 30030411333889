<template>
    <div class="md-layout text-center">
        <div class="md-layout-item md-size-70 md-medium-size-70 md-small-size-70 md-xsmall-size-100">
            <ValidationObserver v-slot="{ handleSubmit, errors }">
                <form @submit.prevent="handleSubmit(login)">
                    <login-card>
                        <div slot="title" class="pt-4">
                            <img src="@/assets/logo-prezero-4drivers.svg" alt="Pre Zero 4 Drivers">
                            <!-- <h3 class="pt-4 text-4xl uppercase">
                                4 Drivers
                            </h3> -->
                        </div>
                        
                        <md-field slot="inputs" :class="[
                            { 'md-valid': errors['email'] && !errors['email'][0] && touchedFields.email },
                            { 'md-error': errors['email'] && errors['email'][0] }
                          ]">
                            <label for="email">{{ $t('fields.email') }}</label>
                            <validation-provider name='email' rules="required|email" v-slot="{ errors}">
                                <md-input v-model="email" name="email" type="email" :placeholder="$t('fields_text.email')" id="email" :disabled="loading"></md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors[0]">close</md-icon>
                                </slide-y-down-transition>
                                <span class="md-error">{{ errors[0] }}</span>
                            </validation-provider>
                        </md-field>
                        <md-field slot="inputs" :class="[
                            { 'md-valid': errors['password'] && !errors['password'][0] && touchedFields.password },
                            { 'md-error': errors['password'] && errors['password'][0] }
                          ]">
                            <label for="password">{{ $t('fields.password') }}</label>
                            <validation-provider name='password' rules="required" v-slot="{ errors}">
                                <md-input v-model="password" name="password" type="password" :placeholder="$t('fields_text.password')" id="password" :disabled="loading"></md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors[0]">close</md-icon>
                                </slide-y-down-transition>
                                <span class="md-error">{{ errors[0] }}</span>
                            </validation-provider>
                        </md-field>
                        <div class="w-full login-footer" slot="footer">
                            <button type="submit" class="w-full btn-primary font-bold py-2 px-4 rounded-lg" id="btnLogin" :disabled="loading">
                                <span v-show="loading">{{ $t('logging_in') }}</span>
                                <span v-show="!loading">{{ $t('enter') }}</span>
                            </button>
                            <div class="text-left mt-2">
                                <router-link :to="{ name: $i18n.locale + '.remember_password' }" class="link-underline">
                                    {{ $t('forgotten_password_message') }}
                                </router-link>
                            </div>
                            <div class="pre-zero-logo">
                                <img src="@/assets/logo_pre_zero.svg" alt="Pre Zero">
                            </div>
                        </div>
                    </login-card>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
import { Auth } from 'aws-amplify';
import LoginCard from "../../Cards/LoginCard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import { Roles } from '@/config';
export default {
    name: "Login",
    components: {
        LoginCard,
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition
    },
    data() {
        return {
            loading: false,
            touchedFields: {
                email: false,
                password: false
            },
            user: null,
            email: null,
            password: null
        };
    },
    watch: {
        email() {
            this.touchedFields.email = true;
        },
        password() {
            this.touchedFields.password = true;
        }
    },
    methods: {
        async login() {
            this.loading = true;
            try {
                this.user = await Auth.signIn(this.email, this.password);
                if (this.user.role == Roles.DRIVER) {
                    await Auth.signOut();
                    this.$noty.error(this.$t('error.not_allowed'));
                } else {
                    this.$router.push({ name: this.$i18n.locale + '.services' });
                }
            } catch (err) {
                console.error('Error login user', err);
                this.$noty.error(this.$t('error.login'));
            }
            this.loading = false;
        },
        reset() {
            this.user = null;
            this.valid = false;
            this.email = '';
            this.password = '';
        },
    }
};
</script>
<style scoped>
.login-footer{
    position: relative;
}
.pre-zero-logo {
    position: absolute;
    bottom: -80px;
    right: -100px;
    width: 8rem;
}
</style>