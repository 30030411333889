<template>
    <div>
        <!-- Toolbar -->
        <div class="bg-white pt-6 md:px-12">
            <!-- Search text -->
            <div>
                <md-field>
                    <label>{{ $t("enter_text") }}</label>
                    <md-input v-model="search" maxlength="50"></md-input>
                </md-field>
            </div>
            <!-- Search Services Active / Closed & Search Status -->
            <div class="grid grid-cols-2 gap-4">
                <md-field>
                    <label for="movie">{{ $t('fields.services_active_close') }}</label>
                    <md-select v-model="search_open_closed" 
						name="services_active_close" 
						id="services_active_close"
                        :placeholder="$t('fields.services_active_close')"
                        :disabled="loading"
                    >
                        <md-option :value="''">
                            {{ $t("all") }}
                        </md-option>
                        <md-option :value="'open'">
                            {{ $t("status_active") }}
                        </md-option>
                        <md-option :value="'closed'">
                            {{ $t("status_closed") }}
                        </md-option>
                    </md-select>
                </md-field>

                <md-field>
                    <label for="movie">{{ $t('fields.service_status') }}</label>
                    <md-select
                        v-model="search_status"
                        name="search_status"
                        id="search_status"
                        :placeholder="$t('fields.service_status')"
                        :disabled="loading"
                    >
                        <md-option :value="''">
                            {{ $t("all") }}
                        </md-option>
                        <md-option v-for="(status,index) in service_status" :key="index" :value="status" 
                        :hidden="status == ServiceStatus.ERRORCLEAR && current_user_role != Roles.ADMIN">
                            {{ $t("service_status." + status) }}
                        </md-option>
                    </md-select>
                </md-field>
            </div>
            <!-- Search Provider & Search Waste Type -->
            <div class="grid grid-cols-2 gap-4">
                <md-field>
                    <label for="movie">{{ $t('fields.provider') }}</label>
                    <md-select
                        v-model="search_provider"
                        name="search_provider"
                        id="search_provider"
                        :placeholder="$t('fields.provider')"
                        :disabled="!canSearchProvider"
                    >
                        <md-option
                            v-for="(provider, index) in providers"
                            :key="index"
                            :value="provider.idProvider"
                        >
                            {{ provider.name }}
                        </md-option>
                    </md-select>
                </md-field>

                <md-field>
                    <label for="search_waste">{{ $t('fields.waste') }}</label>
                    <md-select
                        v-model="search_waste"
                        name="search_waste"
                        id="search_waste"
                        :placeholder="$t('fields.waste')"
                        multiple
                    >
                        <md-option
                            v-for="(wasteType, index) in wasteTypes"
                            :key="index"
                            :value="wasteType.wasteTypeId"
                        >
                            {{ wasteType.wasteDescription }} 
                            <span v-if="wasteType.wasteTypeId">
                                [{{ wasteType.wasteTypeId }}]
                            </span>
                        </md-option>
                    </md-select>
                </md-field>

                <!-- <md-field>
                    <label>{{ $t("fields.waste") }}</label>
                    <md-input v-model="search_waste" maxlength="50"></md-input>
                </md-field> -->
            </div>
            <!-- Dates filter & Logistics Centers -->
            <div class="grid grid-cols-2 gap-4">
                <div class="grid grid-cols-2 gap-2">
                    <md-datepicker v-model="search_date_from" :md-model-type="String">
                        <label>{{ $t("from") }} (dd/mm/yyyy)</label>
                        <span class="md-error">
                            {{ errorDatesMessage }}
                        </span>
                    </md-datepicker>
                    
                    <md-datepicker v-model="search_date_until" :md-model-type="String">
                        <label>{{ $t("until") }} (dd/mm/yyyy)</label>
                    </md-datepicker>
                </div>
                <div>
                    <md-field>
                        <label for="logistics_centers">{{ $t('fields.logistics_centers') }}</label>
                        <md-select v-model="search_logistics_centers" name="logistics_centers" id="logistics_centers" :placeholder="$t('fields.logistics_centers')" multiple :disabled="loading || forceLogiscticsCenters">
                            <md-option v-for="(center, index) in logisticsCenters" :key="index" :value="center.code">
                                {{ center.name }}
                            </md-option>
                        </md-select>
                    </md-field>
                </div>
            </div>
            <!-- License plate & driver filters  -->
            <div class="grid grid-cols-2 gap-4">
                <md-field>
                    <label>{{ $t("enter_license_plate") }}</label>
                    <md-input v-model="search_license_plate" maxlength="50"></md-input>
                </md-field>
                <!-- <md-field>
                    <label>{{ $t("driver") }}</label>
                    <md-input v-model="search_driver" maxlength="50"></md-input>
                </md-field> -->
                <md-autocomplete v-model="search_driver" :md-options="driversMdOptions">
                    <label>{{ $t("driver") }}</label>
                </md-autocomplete>
            </div>
            <!-- Filter buttons -->
            <div class="flex justify-end items-center gap-4">
                <button
                    @click="clearFilter()"
                    class="btn-secondary font-bold py-2 px-4 rounded-xl md:w-40"
                    :disabled="loading"
                >
                    {{ $t("delete_filter") }}
                </button>
                <button
                    @click="filter()"
                    class="btn-primary font-bold py-2 px-4 rounded-xl"
                    :disabled="loading"
                >
                    {{ $t("search") }}
                </button>
            </div>
        </div>
        <!-- Actions & Table -->
        <div class="pt-6 md:px-12">
            <div class="flex flex-wrap justify-end gap-4 pb-4">
                <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
				<button @click="exportTickets()" class="btn-primary font-bold py-2 px-4 rounded-xl" :disabled="loading">
					{{ $t("export_weight_tickets") }}
				</button>
				<button @click="exportServices()" class="btn-primary font-bold py-2 px-4 rounded-xl" :disabled="loading">
					{{ $t("export_services") }}
				</button>
                <button @click="showModalConfirmDesassignUser()" class="btn-primary font-bold py-2 px-4 rounded-xl" :disabled="loading || !same_provider_selected">
					{{ $t("desassign_driver") }}
				</button>
				<button @click="showModalAssignVehicleUser()" class="btn-primary font-bold py-2 px-4 rounded-xl" :disabled="loading || !same_provider_selected">
					{{ $t("assign_vehicle_and_driver") }}
				</button>
				<button @click="showModalAssignVehicle()" class="btn-primary font-bold py-2 px-4 rounded-xl" :disabled="loading || !same_provider_selected">
					{{ $t("assign_vehicle") }}
				</button>
				<button @click="showModalAssignUser()" class="btn-primary font-bold py-2 px-4 rounded-xl" :disabled="loading || !same_provider_selected">
					{{ $t("assign_driver") }}
				</button>
				<button @click="showModalChangeDate()" class="btn-primary font-bold py-2 px-4 rounded-xl" :disabled="loading">
					{{ $t("change_date") }}
				</button>
			</div>
            <div class="table-container">
                <table class="table-services">
                    <tbody>
                        <tr v-for="(service, index) in services" :key="index">
                            <!-- Checkbox -->
                            <td>
                                <md-checkbox
                                    v-model="services_selected"
                                    :value="service"
                                    :disabled="serviceIsClosed(service)"
                                ></md-checkbox>
                            </td>
                            <!-- Date -->
                            <td class="text-center">
                                <div class="text-black">
                                    <div class="uppercase text-xs">
                                        {{
                                            moment(service.date)
                                                .format("ddd")
                                                .slice(0, -1)
                                        }}
                                    </div>
                                    <div class="text-xl">
                                        {{ moment(service.date).format("DD") }}
                                    </div>
                                    <div class="uppercase text-xs">
                                        {{
                                            moment(service.date)
                                                .format("MMM")
                                                .slice(0, -1)
                                        }}
                                    </div>
                                </div>
                            </td>
                            <!-- Service data -->
                            <td>
                                <div>
                                    <div class="flex gap-10">
                                        <span>{{ service.deliveryNote }}</span>
                                        <span v-if="current_user_role == Roles.ADMIN">({{ service.externalId }})</span>
                                        <span>{{ service.vehicle.licensePlate }}</span>
                                    </div>
                                    <div class="flex gap-12 text-main-color uppercase">
                                        <span class="font-bold" :class="{'service-assigned': !serviceAssigned(service)}">
                                            {{ service.client }}
                                        </span>
                                        <span class="text-sm">
                                            {{ service.contactPhone }}
                                        </span>
                                    </div>
                                    <div>
                                        {{ service.serviceAddress }}
                                    </div>
                                    <div>
                                        {{ service.destination }}
                                    </div>
                                    <div>
                                        {{ service.comments }}
                                    </div>
                                </div>
                            </td>
                            <!-- Service actions -->
                            <td>
                                <div class="flex flex-col items-end">
                                    <div class="uppercase pb-2">
                                        {{ service.driver.name }} {{ service.driver.familyName }}
                                    </div>
                                    <div class="table-crud-buttons">
                                        <router-link tag="button" :to="{ 
                                            name: $i18n.locale + '.service_detail',
                                                params: {
                                                    idService: service.idService,
                                                    view: true,
                                                },
                                            }">
                                            <img :src="require('@/assets/icons/icons_actions_view.svg')" />
                                        </router-link>
                                        <router-link tag="button" :to="{ 
                                            name: $i18n.locale + '.service_detail',
                                                params: {
                                                    idService: service.idService,
                                                    editing: !serviceIsClosed(service),
                                                },
                                            }">
                                            <img :src="require('@/assets/icons/icons_actions_edit.svg')" />
                                        </router-link>
                                        <button @click="confirmCancelService(service)">
                                            <img :src="require('@/assets/icons/icons_actions_delete.svg')" />
                                        </button>
                                    </div>
                                    <a @click="openErrorModal(service.status, service.deliveryNote)">
                                        <span class="badge w-28" :class="getClassBadge(service.status)">
                                            <template v-if="current_user_role == Roles.PROVIDERADMIN && service.status == ServiceStatus.ERRORCLEAR">
                                                {{ $t("service_status." + ServiceStatus.FINISHED) }}
                                            </template>
                                            <template v-else>
                                                {{ $t("service_status." + service.status) }}
                                            </template>
                                        </span>
                                    </a>
                                    <div v-if="service.syncDate" class="sync-date">
                                        <span>{{ $t("sync_date") }}:</span>
                                        <br>
                                        <span>{{ service.syncDate | formatDate }}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="no-results" v-if="services.length == 0 && !loading">
                    {{ $t("no_results") }}
                </div>
            </div>
            <!-- Pagination -->
            <div class="flex justify-center pb-6">
                <pagination v-model="page" :noArrows="true" :perPage="perPage" :total="num_services"/>
            </div>
        </div>

        <!-- Modals -->
        <assign-vehicle-modal v-if="showAssignVehicle" @close="closeModalAssignVehicle()" :services="services_selected"/>
        <assign-user-modal v-if="showAssignUser" @close="closeModalAssignUser()" :services="services_selected"/>
        <assign-vehicle-user-modal v-if="showAssignVehicleUser" @close="closeModalAssignVehicleUser()" :services="services_selected" />
        <change-date-modal v-if="showChangeDate" @close="closeModalChangeDate()" :services="services_selected"/>
        <modal v-if="showConfirmCancel" @close="showConfirmCancel = false">
			<h3 slot="header">
				{{ $t("confirm") }}
			</h3>
			<div slot="body">
				<i18n path="confirm_messages.cancel_service" tag="p">
					<template #service>
						{{ serviceToCancel.deliveryNote }}
					</template>
				</i18n>
			</div>
			<div slot="footer" class="flex gap-4">
				<button @click="showConfirmCancel = false"
					class="btn-secondary font-bold py-2 px-4 rounded-xl">
					{{ $t("back") }}
				</button>
				<button @click="cancelService(serviceToCancel)"
					class="btn-primary font-bold py-2 px-4 rounded-xl">
					{{ $t("cancel_service") }}
				</button>
			</div>
		</modal>
        <modal v-if="showConfirmDesassignUser" @close="showConfirmDesassignUser = false">
			<h3 slot="header">
				{{ $t("confirm") }}
			</h3>
			<div slot="body">
				{{ $t("confirm_messages.desassign_driver") }}
			</div>
			<div slot="footer" class="flex gap-4">
				<button @click="showConfirmDesassignUser = false"
					class="btn-secondary font-bold py-2 px-4 rounded-xl">
					{{ $t("back") }}
				</button>
				<button @click="desassignServices()"
					class="btn-primary font-bold py-2 px-4 rounded-xl">
					{{ $t("desassign_driver") }}
				</button>
			</div>
		</modal>
        <modal v-if="showErrorDetail" @close="showErrorDetail = false" class="modal-errors">
			<h3 slot="header">
				{{ $t("error.error") }}
			</h3>
			<div slot="body" class="errors-container">
                <div v-for="(syncError, index) in syncErrors" :key="index">
                    {{ syncError }}
                </div>
			</div>
			<div slot="footer" class="flex gap-4">
				<button @click="showErrorDetail = false"
					class="btn-secondary font-bold py-2 px-4 rounded-xl">
					{{ $t("back") }}
				</button>
			</div>
		</modal>

    </div>
</template>
<script>
import exportFromJSON from "export-from-json";
import { 
    getServices,
    getServicesAmounts,
    getServicesTickets,
    getProviderServices, 
    cancelService,
    desassignServicesFromUser, 
    getServiceFilters 
} from "@/services/services";
import {
    getSyncLogs,
} from "@/services/sync";
import { getUserLogisticsCenters } from '@/services/users';
import { ServiceStatus, Roles } from "@/config";
import authMixin from "@/mixins/auth";
import localeMixin from "@/mixins/locale";
import paginationMixin from "@/mixins/pagination";
import Modal from '@/components/Modal.vue';
import AssignVehicleModal from './AssignVehicleModal.vue';
import AssignUserModal from './AssignUserModal.vue';
import AssignVehicleUserModal from './AssignVehicleUserModal.vue';
import ChangeDateModal from './ChangeDateModal.vue';

export default {
	mixins: [authMixin, localeMixin, paginationMixin],
    components: {
        Modal,
        AssignVehicleModal,
        AssignUserModal,
        AssignVehicleUserModal,
        ChangeDateModal,
	},
    data() {
        return {
            loading: false,
            showAssignVehicle: false,
            showAssignUser: false,
            showAssignVehicleUser: false,
            showChangeDate: false,
            showConfirmCancel: false,
            showConfirmDesassignUser: false,
            showErrorDetail: false,
            search: null,
            search_open_closed: null,
            search_status: null,
            canSearchProvider: true,
            search_provider: null,
            search_waste: [],
            search_date_from: null,
            search_date_until: null,
            search_logistics_centers: [],
            search_license_plate: null,
            search_driver: "",
            services_selected: [],
            same_provider_selected: true,
            serviceToCancel: {},
            ServiceStatus,
            providers: [],
            wasteTypes: [],
            logisticsCenters: [],
            drivers: [],
            num_services: 0,
            services: [],
            service_status: ServiceStatus,
            errorDatesMessage: "",
            current_user_role: null,
            Roles: Roles,
            syncErrors: [],
            forceLogiscticsCenters: false,
        };
    },

    computed: {
        driversMdOptions() {
            let options = []
            if (this.drivers) {
                this.drivers.forEach(driver => {
                    const driverOption = driver.name + ' ' + driver.familyName + ', ' + driver.email;
                    options.push(driverOption);
                });
            }
            return options;
        },
        searchDriverId() {
            let driverId = null;
            if (this.search_driver) {
                const searchDriverSplit = this.search_driver.split(', ');
                const searchDriverEmail = searchDriverSplit[1];
                const searchDriver = this.drivers.find((driver) => driver.email == searchDriverEmail);
                driverId = searchDriver.idUser;
            }

            return driverId;
        },

        isSelectedPending() {
            let isOnlyPending = true;
            this.services_selected.forEach(service => {
                if (service.status != ServiceStatus.PENDING) {
                    isOnlyPending = false;
                }
            });
            return isOnlyPending;
        },
    },

    watch: {
        services_selected(services) {
            // Check selected services same provider
            if (this.current_user_role == Roles.ADMIN) {
                let same_provider_selected = true;
                for (const service of services) {
                    const firstIdProvider = services[0].provider.idProvider;
                    const actualIdProvider = service.provider.idProvider;
                    if (firstIdProvider != actualIdProvider) {
                        same_provider_selected = false;
                        this.$noty.warning(this.$t("warning.different_providers"));
                        break;
                    }
                }
                this.same_provider_selected = same_provider_selected;
            }
        },
        page() {
            this.fetchServices();
        }
    },

    created: async function () {
        this.loading = true;
        // Set pagination perPage from mixin
        this.perPage = 10;
        await this.fetchFilters();
        this.getLocalStorageFilters();
        await this.fetchCurrentUserLogisticsCenters();
        this.page = this.getLocalStoragePage("servicesPage");
        await this.fetchServices();
		this.configDatePickerLocale();
        this.current_user_role = await this.getCurrentUserRole();
        this.loading = false;
    },

    beforeRouteLeave: function(to, from, next) {
        if (!to.name.includes("service_detail")) {
            this.clearLocalStorageFilters();
            localStorage.removeItem('servicesPage');
        }
        next();
    },

    methods: {       
        getClassBadge(status) {
            if (status == ServiceStatus.FINISHED) {
                return "badge-success";
            }
            if (status == ServiceStatus.INPROGRESS) {
                return "badge-primary";
            }
            if (status == ServiceStatus.PENDING) {
                return "badge-secondary";
            }
            if (status == ServiceStatus.FAILED) {
                return "badge-danger";
            }
            if (status == ServiceStatus.PAUSED) {
                return "badge-warning";
            }
            if (status == ServiceStatus.ERRORCLEAR) {
                return "badge-warning";
            }
            return "";
        },

        async filter() {
            this.loading = true;
            // Check dates filter
            if (this.search_date_from && this.search_date_until && this.search_date_from != this.search_date_until) {
                const momentFrom = this.moment(this.search_date_from, "DD/MM/YYYY");
                const momentUntil = this.moment(this.search_date_until, "DD/MM/YYYY");
                const isAfter = this.moment(momentUntil).isAfter(momentFrom);
                if (!isAfter) {
                    this.errorDatesMessage = this.$t("error.date_after");
                    this.loading = false;
                    return;
                } else {
                    this.errorDatesMessage = "";
                }
            }
            this.setLocalStorageFilters();
            this.page = 1;
            this.setLocalStoragePage("servicesPage",this.page);
            await this.fetchServices();
            this.loading = false;
        },

        async clearFilter() {
            this.search = null;
            this.search_open_closed = null;
            this.search_status = null;
            this.search_provider = null;
            this.search_waste = [];
            this.search_date_from = null;
            this.search_date_until = null;
            this.search_logistics_centers = [];
            this.search_license_plate = null,
            this.search_driver = "",
            this.errorDatesMessage = "";
            this.clearLocalStorageFilters();
            this.page = 1;
            this.setLocalStoragePage("servicesPage", this.page);
            await this.fetchCurrentUserLogisticsCenters();
            this.fetchServices();
        },

        getLocalStorageFilters() {
            const serviceFilters = JSON.parse(localStorage.getItem("serviceFilters"));
            if (serviceFilters) {
                this.search = serviceFilters.search;
                this.search_date_from = serviceFilters.search_date_from;
                this.search_date_until = serviceFilters.search_date_until;
                this.search_open_closed = serviceFilters.search_open_closed;
                this.search_provider = serviceFilters.search_provider;
                this.search_status = serviceFilters.search_status;
                this.search_waste = serviceFilters.search_waste;
                this.search_logistics_centers = serviceFilters.search_logistics_centers;
                this.search_license_plate = serviceFilters.search_license_plate;
                this.search_driver = serviceFilters.search_driver;
            }
        },

        setLocalStorageFilters() {
            const serviceFilters = {
                search: this.search,
                search_open_closed: this.search_open_closed,
                search_status: this.search_status,
                search_provider: this.search_provider,
                search_waste: this.search_waste,
                search_date_from: this.search_date_from,
                search_date_until: this.search_date_until,
                search_logistics_centers: this.search_logistics_centers,
                search_license_plate: this.search_license_plate,
                search_driver: this.search_driver,
            }
            localStorage.setItem("serviceFilters", JSON.stringify(serviceFilters));
        },

        clearLocalStorageFilters() {
            localStorage.removeItem("serviceFilters");
        },

        async fetchFilters() {
            const serviceFilters = await getServiceFilters();
            this.providers = serviceFilters.providers;
            this.wasteTypes = serviceFilters.wasteTypes;
            this.logisticsCenters = serviceFilters.logisticsCenters;
            if (this.currentUser.role == "providerAdmin") {
                this.drivers = serviceFilters.drivers.filter((driver) => driver.provider.idProvider == this.currentUser.provider);
            } else {
                this.drivers = serviceFilters.drivers;
            }
        },

        async fetchCurrentUserLogisticsCenters() {
            const currentUser = {
                idUser: this.currentUser.username,
            }
            const response = await getUserLogisticsCenters(currentUser);
            if (response && response.data && response.data.length) {
                this.search_logistics_centers = response.data;
                this.forceLogiscticsCenters = true;
            }
        },

        async fetchServices() {
            this.loading = true;
            this.services_selected = [];
            // Parse dates
            let search_date_fromISO = null;
            if (this.search_date_from) {
                search_date_fromISO = this.parseSearchDate(this.search_date_from);
            }
            let search_date_untilISO = null;
            if (this.search_date_until) {
                search_date_untilISO = this.parseSearchDate(this.search_date_until);
            }
            const searchDriverId = this.searchDriverId;
            // Check user role
            const currentUserRole = await this.getCurrentUserRole();
            if (currentUserRole == Roles.PROVIDERADMIN) {
                this.canSearchProvider = false;
                const response = await getProviderServices(
                    this.page,
                    this.currentUser.provider,
                    this.search,
                    search_date_fromISO,
                    search_date_untilISO,
                    this.search_open_closed,
                    this.search_status,
                    this.search_waste,
                    this.search_logistics_centers,
                    this.search_license_plate,
                    searchDriverId,
                );
                this.num_services = response.numServices;
                this.services = response.services;
            } else {
                const response = await getServices(
                    this.page,
                    this.search,
                    search_date_fromISO,
                    search_date_untilISO,
                    this.search_open_closed,
                    this.search_status,
                    this.search_provider,
                    this.search_waste,
                    this.search_logistics_centers,
                    this.search_license_plate,
                    searchDriverId,
                );
                if (!response) {
                    this.$noty.error(this.$t("error.loading"));
                    this.loading = false;
                    return
                }
                this.num_services = response.numServices;
                this.services = response.services;
            }
            this.loading = false;
        },

        showModalAssignVehicle() {
            if (this.services_selected.length >= 1) {
                this.showAssignVehicle = true;
            } else {
                this.$noty.warning(this.$t("warning.no_services"));
            }
        },

        async closeModalAssignVehicle() {
            await this.fetchServices();
            this.showAssignVehicle = false;
        },

        showModalAssignUser() {
            if (this.services_selected.length >= 1) {
                this.showAssignUser = true;
            } else {
                this.$noty.warning(this.$t("warning.no_services"));
            }
        },

        async closeModalAssignUser() {
            await this.fetchServices();
            this.showAssignUser = false;
        },

        showModalAssignVehicleUser() {
            if (this.services_selected.length >= 1) {
                this.showAssignVehicleUser = true;
            } else {
                this.$noty.warning(this.$t("warning.no_services"));
            }
        },

        async closeModalAssignVehicleUser() {
            await this.fetchServices();
            this.showAssignVehicleUser = false;
        },

        showModalChangeDate() {
            if (this.services_selected.length >= 1) {
                this.showChangeDate = true;
            } else {
                this.$noty.warning(this.$t("warning.no_services"));
            }
        },

        async closeModalChangeDate() {
            await this.fetchServices(); 
            this.showChangeDate = false;
        },

        async confirmCancelService(service) {
            // Check service status & user role
            const currentUserRole = await this.getCurrentUserRole();
            if (this.serviceIsClosed(service) || currentUserRole == Roles.ADMIN) {
                this.$noty.warning(this.$t("warning.no_edit_service"));
            } else {
                this.serviceToCancel = service;
            this.showConfirmCancel = true;
            }
        },

        async cancelService(service) {
            this.loading = true;
            const response = await cancelService(service.idService);
            if (response && response.status == 204) {
                this.$noty.success(this.$t('success.service_canceled'));
                await this.fetchServices(); 
                this.showConfirmCancel = false;
            } else {
                this.$noty.error(this.$t('error.saved'));
            }
            this.loading = false;
        },

        serviceIsClosed(service) {
            if (service.status == ServiceStatus.FAILED || service.status == ServiceStatus.FINISHED) {
                return true;
            } else {
                return false;
            }
        },

        parseSearchDate(date) {
            const momentObject = this.moment(date, "DD/MM/YYYY");
            const dateISO = momentObject.format("YYYY-MM-DD");
            return dateISO;
        },

        serviceAssigned(service) {
            return Object.keys(service.driver).length > 0;
        },

        async openErrorModal(serviceStatus, serviceDeliveryNote) {
            if (serviceStatus == ServiceStatus.ERRORCLEAR) {
                const response = await getSyncLogs(1, serviceDeliveryNote);
                if (response) {
                    this.syncErrors = response.syncLogs;
                }
                this.showErrorDetail = true;
            }
        },

        async exportServices() {
            this.loading = true;

            // Parse dates
            let search_date_fromISO = null;
            if (this.search_date_from) {
                search_date_fromISO = this.parseSearchDate(this.search_date_from);
            }
            let search_date_untilISO = null;
            if (this.search_date_until) {
                search_date_untilISO = this.parseSearchDate(this.search_date_until);
            }
            let search_logistics_centers = [];
            if (this.search_logistics_centers.length ) {
                search_logistics_centers = this.search_logistics_centers;
            }

            const currentUserRole = await this.getCurrentUserRole();
            const searchProvider = (currentUserRole == Roles.PROVIDERADMIN) ? this.currentUser.provider : this.search_provider;

            const servicesLines = await getServicesAmounts(
                this.search,
                search_date_fromISO,
                search_date_untilISO,
                this.search_open_closed,
                this.search_status,
                searchProvider,
                this.search_waste,
                search_logistics_centers,
            );
            if (servicesLines) {
                const nowTime = this.moment().format('YYYYMMDD_HHmm');

                const fileName = "Servicios_" + nowTime;
                const exportType = exportFromJSON.types.xls;
                exportFromJSON({ data: servicesLines, fileName, exportType });
            }

            this.loading = false;
        },

        async exportTickets() {
            this.loading = true;

            // Parse dates
            let search_date_fromISO = null;
            if (this.search_date_from) {
                search_date_fromISO = this.parseSearchDate(this.search_date_from);
            }
            let search_date_untilISO = null;
            if (this.search_date_until) {
                search_date_untilISO = this.parseSearchDate(this.search_date_until);
            }
            let search_logistics_centers = [];
            if (this.search_logistics_centers.length ) {
                search_logistics_centers = this.search_logistics_centers;
            }

            const currentUserRole = await this.getCurrentUserRole();
            const searchProvider = (currentUserRole == Roles.PROVIDERADMIN) ? this.currentUser.provider : this.search_provider;

            const servicesLines = await getServicesTickets(
                this.search,
                search_date_fromISO,
                search_date_untilISO,
                this.search_open_closed,
                this.search_status,
                searchProvider,
                this.search_waste,
                search_logistics_centers,
            );
            if (servicesLines) {
                const nowTime = this.moment().format('YYYYMMDD_HHmm');

                const fileName = "Tickets_de_peso_" + nowTime;
                const exportType = exportFromJSON.types.xls;
                exportFromJSON({ data: servicesLines, fileName, exportType });
            }

            this.loading = false;
        },

        showModalConfirmDesassignUser() {
            const isSelectedPending = this.isSelectedPending;

            if (isSelectedPending) {
                this.showConfirmDesassignUser = true;
            } else {
                this.$noty.warning(this.$t('warning.desassign_pending'));
            }
        },

        async desassignServices() {
            this.loading = true;

            const servicesExternalIds = this.services_selected.map(service => service.externalId);

            const response = await desassignServicesFromUser(servicesExternalIds);
            if (response) {
                this.$noty.success(this.$t('success.desassign_driver'));
                await this.fetchServices();
                this.showConfirmDesassignUser = false;
            } else {
                this.$noty.error(this.$t('error.saved'));
            }

            this.loading = false;
        },
    },
};
</script>

<style scoped>
.service-assigned {
    color: #ff1744 !important;
}
.sync-date {
    font-size: 12px;
}
</style>