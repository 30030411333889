import { API, Auth } from "aws-amplify";
import util from "util";

/**
 * Sync Up Provider
 *
 * @param {uuid} idProvider - UUID Provider
 * @returns {Promise} Promise - object with the result of the api call response
 */
const syncProvider = async (idProvider) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/sync/' + idProvider];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.put(API_NAME, path, myInit);
        
        return response;
    } catch (error) {
        console.error(
            "Error synchronizing provider: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        if (error.response.status == 504) {
            return error.response;
        }
        return false;
    }
};

/**
 * Get Synchronization Logs
 *
 * @returns {Promise} Promise - object with the result of the api call response
 */
const getSyncLogs = async (page = 1, filter = null) => {
    let responseData = {};
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/sync-logs'];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const queryStringParameters = {
        page: page,
        filter: filter,
    };
    const myInit = {
        headers: headers,
        response: true,
        queryStringParameters: queryStringParameters,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        responseData = response.data;
    } catch (error) {
        console.error(
            "Error getting synchronization logs: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return responseData;
};

export {
    syncProvider,
    getSyncLogs,
}