<template>
    <table class="table-assign-modal mt-4">
        <thead class="underline text-main-color text-left">
            <tr>
                <th></th>
                <th class="text-left">
                    {{ $t("name") }}
                </th>
                <th class="text-left">
                    {{ $t("email") }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(user, index) in users" :key="index">
                <td>
                    <md-checkbox v-model="user_selected" @change="handleInput" :value="user.idUser"></md-checkbox>
                </td>
                <td class="text-left">
                    {{ user.name }} {{ user.familyName }}
                </td>
                <td class="text-left">
                    {{ user.email }}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
        },
        users: {
            type: Array,
        },
    },

    data() {
        return {
            user_selected: this.value,
        }
    },

    methods: {
        handleInput(idUser) {
            this.user_selected = idUser;
            this.$emit('input', this.user_selected);
        }
    },
};
</script>