import { Auth, Hub } from 'aws-amplify';
import { Roles } from '../config';

export default {
    data() {
        return {
            currentUser: {
                admin: false,
                alias: null,
                role: null,
                provider: null,
            },
        }
    },

    computed: {
        isLoggedIn: function() {
            return this.currentUser != null && this.currentUser.username && this.currentUser.username != '';
        },
        isAdmin: function() {
            return this.currentUser != null && this.currentUser.admin;
        },
        currentUserRole: function() {
            return this.currentUser != null && this.currentUser.role;
        },
    },

    async beforeCreate() {
        Hub.listen('auth', data => {
            const { payload } = data
            /*
            const locale = this.$i18n.locale;
            */
            if (payload.event === 'signIn') {
                this.setAuthUser(payload.data);
            }
            if (payload.event === 'signOut') {
                this.unsetAuthUser();
            }
        })

        try {
            var user = await Auth.currentAuthenticatedUser();
            this.setAuthUser(user);
        } catch (error) {
            console.info('Not logged in');
        }
    },

    methods: {
        setAuthUser(user) {
            this.currentUser = user;
            this.currentUser.alias = user.attributes.preferred_username;
            this.currentUser.role = user.attributes['custom:role'];
            this.currentUser.provider = user.attributes['custom:provider'];
            if (this.currentUser.role == Roles.ADMIN) {
                this.currentUser.admin = true;
            } else {
                this.currentUser.admin = false;
            }
            // console.log("current user from auth", this.currentUser);
        },
        unsetAuthUser() {
            this.currentUser = {
                admin: false,
                alias: null,
                role: null,
            }
        },
        async getCurrentUserRole() {
            let user = await Auth.currentAuthenticatedUser();
            const role = user.attributes['custom:role'];
            return role;
        },
        async getCurrentUserProvider() {
            let user = await Auth.currentAuthenticatedUser();
            const provider = user.attributes['custom:provider'];
            return provider;
        },
        async checkPermissions() {
            const route = this.$route.name.substr(3);
            const user = await Auth.currentAuthenticatedUser();
            const role = user.attributes['custom:role'];
            let permission = true;
            if (route == 'users' && role == Roles.PROVIDERADMIN) {
                permission = false;
            } else if (route == 'user_create' && role == Roles.PROVIDERADMIN) {
                permission = false;
            } else if (route == 'user_detail' && role == Roles.PROVIDERADMIN) {
                permission = false;
            } else if (route == 'logistics_center' && role == Roles.PROVIDERADMIN) {
                permission = false;
            } else if (route == 'logistics_centers_form' && role == Roles.PROVIDERADMIN) {
                permission = false;
            } else if (route == 'providers' && role == Roles.PROVIDERADMIN) {
                permission = false;
            }  else if (route == 'provider_form' && role == Roles.PROVIDERADMIN) {
                permission = false;
            } else if (route == 'synchronization' && role == Roles.PROVIDERADMIN) {
                permission = false;
            }
            if (!permission) {
                this.$noty.error(this.$t('error.not_allowed'));
                this.$router.push({ name: this.$i18n.locale + '.services' });
            }
        },
    }
};