import { API, Auth } from 'aws-amplify';
import util from 'util';

/**
 * Get logistics centers list
 *
 * @param {string} filter - Filter string
 * @returns {Promise} Promise - object with the result of the api call response
 */
const getLogisticsCenters = async (page = 1, filter = null) => {
    let responseData = {};
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/logisticsCenter'];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const queryStringParameters = {
        page: page,
        filter: filter,
    };
    const myInit = {
        headers: headers,
        response: true,
        queryStringParameters: queryStringParameters,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        responseData = response.data;
    } catch (error) {
        console.error(
            "Error getting logistics centers: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return responseData;
}

/**
 * Get logistics center detail
 *
 * @param {Object} logisticsCenter - logistics center object
 * @returns {Promise} Promise - object with the result of the api call response
 */
const getLogisticsCenter = async (idCenter) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/logisticsCenter/' + idCenter];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error getting logistics center detail: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
}

/**
 * Create logistics center
 *
 * @param {Object} logisticsCenter - logistics center object
 * @returns {Promise} Promise - object with the result of the api call response
 */
 const createLogisticsCenter = async (logisticsCenter) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/logisticsCenter'];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: logisticsCenter,
    };
    try {
        var response = await API.post(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error creating logistics center: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
}

/**
 * Update logistics center
 *
 * @param {Object} logisticsCenter - logistics center object
 * @returns {Promise} Promise - object with the result of the api call response
 */
 const updateLogisticsCenter = async (logisticsCenter) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/logisticsCenter/' + logisticsCenter.idCenter];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: logisticsCenter,
    };
    try {
        var response = await API.put(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error updating logistics center: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
}

/**
 * Delete logistics center
 *
 * @param {Object} logisticsCenter - logistics center object
 * @returns {Promise} Promise - object with the result of the api call response
 */
 const deleteLogisticsCenter = async (logisticsCenter) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/logisticsCenter/' + logisticsCenter.idCenter];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.del(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error deleting logistics center: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
}



export {
    getLogisticsCenters,
    getLogisticsCenter,
    createLogisticsCenter,
    updateLogisticsCenter,
    deleteLogisticsCenter,
}