<template>
	<div>
		<!-- Toolbar -->
		<div class="bg-white pt-6 md:px-12 pb-3">
			<router-link :to="{ name: $i18n.locale + '.vehicle_form', params: { creating: true } }" tag="button"	
				class="btn-add float-right">
				{{ $t("add") }}
			</router-link>
			<!-- Search text -->
			<div>
				<md-field>
					<label>{{ $t("enter_text") }}</label>
					<md-input v-model="search" maxlength="50" :disabled="loading"></md-input>
				</md-field>
			</div>
			<!-- Search motorized & active -->
			<div class="grid grid-cols-2 gap-4">
                <md-field>
					<label for="movie">{{ $t('fields.motorized') }}</label>
					<md-select v-model="search_motorized" name="motorized" id="motorized" :placeholder="$t('fields.motorized')" :disabled="loading">
						<md-option :value="''">
							{{ $t("all") }}
						</md-option>
						<md-option :value="true">
							{{ $t("yes") }}
						</md-option>
						<md-option :value="false">
							{{ $t("no") }}
						</md-option>
					</md-select>
                </md-field>

                <md-field>
					<label for="movie">{{ $t('fields.active') }}</label>
					<md-select v-model="search_active" name="active" id="motorized" :placeholder="$t('fields.active')" :disabled="loading">
						<md-option :value="''">
							{{ $t("all") }}
						</md-option>
						<md-option :value="true">
							{{ $t("yes") }}
						</md-option>
						<md-option :value="false">
							{{ $t("no") }}
						</md-option>
					</md-select>
                </md-field>
            </div>
			<!-- Search Provider & Buttons -->
			<div class="grid grid-cols-2 gap-4">
				<div>
					<md-field>
						<label for="movie">{{ $t('fields.provider') }}</label>
                        <md-select v-model="search_provider" name="search_provider" id="search_provider" :placeholder="$t('fields.provider')" :disabled="loading || forceProvider">
                            <md-option v-for="(provider, index) in providers" :key="index" :value="provider.idProvider">
                                {{ provider.name }}
                            </md-option>
                        </md-select>
                </md-field>
				</div>
				<div class="flex justify-end items-center gap-10">
					<div class="grow flex gap-2">
						<md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
						<button @click="clearFilter()" class="btn-secondary font-bold py-2 px-4 rounded-xl md:w-40">
							{{ $t("delete_filter") }}
						</button>
						<button @click="filter()" class="btn-primary font-bold py-2 px-4 rounded-xl">
							{{ $t("search") }}
						</button>
					</div>
				</div>
			</div>
			
		</div>
		<!-- Table -->
		<div class="pt-6 md:px-12">
			<table class="table-rows-rounded min-w-full">
				<thead class="underline text-center">
					<tr>
						<th>
							{{ $t("license_plate") }}
						</th>
						<th>
							{{ $t("haulier") }}
						</th>
						<th>
							{{ $t("motor") }}
						</th>
						<th>
							{{ $t("active") }}
						</th>
						<th>
							{{ $t("provider") }}
						</th>
						<th>
							<!-- Buttons -->
						</th>
					</tr>
				</thead>
				<tbody class="text-center">
					<tr v-for="(vehicle, index) in vehicles" :key="index">
						<td>
							{{ vehicle.licensePlate }}
						</td>
						<td>
							{{ vehicle.provider.haulierCode }}
						</td>
						<td>
							{{ vehicle.motorized ? $t("yes") : $t("no") }}
						</td>
						<td>
							{{ vehicle.active ? $t("yes") : $t("no") }}
						</td>
						<td>
							{{ vehicle.provider.name }}
						</td>
						<td class="text-right">
							<div class="table-crud-buttons">
								<router-link tag="button" :to="{ 
									name: $i18n.locale + '.vehicle_form',
										params: {
											vehicle: vehicle,
											view: true,
										},
									}">
									<img :src="require('@/assets/icons/icons_actions_view.svg')"/>
								</router-link>
								<router-link tag="button" :to="{ 
									name: $i18n.locale + '.vehicle_form',
										params: {
											vehicle: vehicle,
											editing: true,
										},
									}">
									<img :src="require('@/assets/icons/icons_actions_edit.svg')"/>
								</router-link>
								<button @click="confirmDelete(vehicle)">
									<img :src="require('@/assets/icons/icons_actions_delete.svg')"/>
								</button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<!-- Pagination -->
            <div class="flex justify-center pb-6">
                <pagination v-model="page" :noArrows="true" :perPage="perPage" :total="num_vehicles"/>
            </div>
		</div>
		<modal v-if="showConfirm" @close="showConfirm = false">
			<h3 slot="header">
				{{ $t("confirm") }}
			</h3>
			<div slot="body">
				<i18n path="confirm_messages.delete_vehicle" tag="p">
					<template #vehicle>
						{{ vehicleToDelete.licensePlate }}
					</template>
				</i18n>
			</div>
			<div slot="footer" class="flex gap-4">
				<button @click="showConfirm = false"
					class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-xl">
					{{ $t("cancel") }}
				</button>
				<button @click="deleteVehicle(vehicleToDelete)"
					class="btn-secondary font-bold py-2 px-4 rounded-xl">
					{{ $t("delete") }}
				</button>
			</div>
		</modal>
	</div>
</template>
<script>
import Modal from '@/components/Modal.vue';
import { getServiceFilters } from "@/services/services";
import { getVehicles, deleteVehicle, getProviderVehicles } from '@/services/vehicles';
import authMixin from "@/mixins/auth";
import paginationMixin from "@/mixins/pagination";
import { Roles } from '@/config';
export default {
	mixins: [authMixin, paginationMixin],
	components: {
		Modal,
	},
	data () {
		return {
			loading: false,
			showConfirm: false,
			forceProvider: false,
			search: "",
			search_motorized: null,
			search_active: null,
			search_provider: null,
			providers: [],
			vehicleToDelete: {},
			num_vehicles: 0,
			vehicles: [],
		};
	},

	watch: {
		page() {
            this.fetchVehicles();
        }
	},

	created: async function() {
		this.loading = true;
		this.fetchProviders();
		this.fetchVehicles();
		this.loading = false;
	},

	methods: {
		confirmDelete(vehicle) {
			this.vehicleToDelete = vehicle;
			this.showConfirm = true;
		},

		async filter() {
			this.loading = true;
			this.fetchVehicles();
			this.loading = false;
		},

		async clearFilter() {
			this.search = "";
			this.search_motorized = null;
			this.search_active = null;
			this.search_provider = null;
			this.fetchVehicles();
		},

		async fetchVehicles() {
			this.loading = true;
			const currentUserRole = await this.getCurrentUserRole();
			const motorizedFilter = this.search_motorized === "" ? null : this.search_motorized;
			const activeFilter = this.search_active === "" ? null : this.search_active;
			if (currentUserRole == Roles.PROVIDERADMIN) {
				this.forceProvider = true;
				const response = await getProviderVehicles(this.currentUser.provider, this.page, this.search, motorizedFilter, activeFilter);
				this.num_vehicles = response.numVehicles;
				this.vehicles = response.vehicles;
			} else {
				const response = await getVehicles(this.page, this.search, motorizedFilter, activeFilter, this.search_provider);
				this.num_vehicles = response.numVehicles;
				this.vehicles = response.vehicles;
			}
			this.loading = false;
		},

		async fetchProviders () {
			this.loading = true;
			const serviceFilters = await getServiceFilters();
            this.providers = serviceFilters.providers;
			this.loading = false;
		},

		async deleteVehicle(vehicle) {
			this.loading = true;
			await deleteVehicle(vehicle);
			this.showConfirm = false;
			this.vehicleToDelete = {};
			this.fetchVehicles();
			this.$noty.success(this.$t('success.vehicle_deleted'));
			this.loading = false;
		}
	},
}
</script>