<template>
  <!--div-->
    <!--img alt="Vue logo" src="./assets/logo.png"-->
    <router-view keep-alive :key="$route.fullPath"></router-view>
  <!--/div-->
</template>

<script>
import Amplify, { Storage, API } from 'aws-amplify';
import authMixin from "./mixins/auth";

export default {
  mixins: [authMixin],
  components: {
  },
  beforeCreate: () => {
      var apiConfig = {
          endpoints: [{
              name: process.env.VUE_APP_API_NAME,
              endpoint: process.env.VUE_APP_API_URL,
              region: process.env.VUE_APP_REGION
          }]
      };
      //Establecimiento de variable de entorno de dominio
      var domain = 'localhost';
      if (process.env.VUE_APP_DOMAIN) {
          domain = process.env.VUE_APP_DOMAIN;
      }
      Amplify.configure({
          Auth: {
              region: process.env.VUE_APP_REGION,
              userPoolId: process.env.VUE_APP_USER_POOL_ID,
              userPoolWebClientId: process.env.VUE_APP_APP_CLIENT_ID_WEB,
              clientId: process.env.VUE_APP_CLIENT_ID,
              identityPoolId: process.env.VUE_APP_PROVIDER_ID,
              mandatorySignIn: false,
              cookieStorage: {
                domain: domain,
                secure: true,
                path: '/',
                expires: 1,
              },
          },
          API: apiConfig,
          Storage: {
              AWSS3: {
                  bucket: process.env.VUE_APP_BUCKET,
                  region: process.env.VUE_APP_REGION
              }
          }
      });

      Storage.configure({
          level: 'public',
          customPrefix: {
              public: ''
          }
      });

      API.configure(apiConfig);
  }
}
</script>
