var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white pb-4 md:px-12"},[_c('div',{staticClass:"flex justify-end"},[_c('a',{staticClass:"back-button",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("arrow_back")]),_vm._v(" "+_vm._s(_vm.$t("back"))+" ")])]),_c('ValidationObserver',{ref:"form",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('md-field',{class:[
            { 'md-valid': errors['code'] && !errors['code'][0] && _vm.touchedFields.code },
            { 'md-error': errors['code'] && errors['code'][0] }
            ]},[_c('label',{attrs:{"for":"code"}},[_vm._v(_vm._s(_vm.$t('fields.code')))]),_c('validation-provider',{attrs:{"name":"code","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var touched = ref.touched;
return [_c('md-input',{attrs:{"name":"code","type":"text","placeholder":_vm.$t('fields_text.code'),"id":"code","disabled":_vm.loading || _vm.view},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"error"},[_vm._v("close")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',[_c('md-field',{class:[
            { 'md-valid': errors['description'] && !errors['description'][0] && _vm.touchedFields.description },
            { 'md-error': errors['description'] && errors['description'][0] }
            ]},[_c('label',{attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t('fields.name')))]),_c('validation-provider',{attrs:{"name":"name","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var touched = ref.touched;
return [_c('md-input',{attrs:{"name":"description","type":"text","placeholder":_vm.$t('fields_text.name'),"id":"name","disabled":_vm.loading || _vm.view},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"error"},[_vm._v("close")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',[_c('md-field',{class:[
            { 'md-valid': errors['prefix'] && !errors['prefix'][0] && _vm.touchedFields.prefix },
            { 'md-error': errors['prefix'] && errors['prefix'][0] }
            ]},[_c('label',{attrs:{"for":"prefix"}},[_vm._v(_vm._s(_vm.$t('fields.prefix')))]),_c('validation-provider',{attrs:{"name":"prefix","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var touched = ref.touched;
return [_c('md-input',{attrs:{"name":"prefix","type":"text","placeholder":_vm.$t('fields_text.prefix'),"id":"prefix","disabled":_vm.loading || _vm.view},model:{value:(_vm.prefix),callback:function ($$v) {_vm.prefix=$$v},expression:"prefix"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"error"},[_vm._v("close")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(!_vm.view)?_c('div',{staticClass:"flex justify-end gap-4 mt-4"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.editing || _vm.view),expression:"editing || view"}],staticClass:"btn-secondary font-bold py-2 px-4 rounded-xl md:w-40",attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();_vm.showConfirm = true}}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]),_c('button',{staticClass:"btn-primary font-bold py-2 px-4 rounded-xl",attrs:{"type":"submit","disabled":_vm.loading || _vm.view},on:{"click":function($event){$event.preventDefault();return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }