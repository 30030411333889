<template>
    <modal class="modal-assign-vehicle" @close="closeModal()">
        <h3 slot="header" class="modal-title">
            {{ $t("assign_vehicle") }}
        </h3>
        <div slot="body">
            <div>
                <md-field>
                    <label>{{ $t("license_plate") }}</label>
                    <md-input v-model="search" maxlength="50"></md-input>
                </md-field>
            </div>
            <div class="flex gap-4 justify-end items-center">
                <button
                        @click="clearFilter()"
                        class="btn-secondary font-bold py-2 px-4 rounded-xl md:w-40"
                    >
                        {{ $t("delete_filter") }}
                    </button>
                <button
                    @click="filter()"
                    class="btn-primary font-bold py-2 px-4 rounded-xl"
                >
                    {{ $t("search") }}
                </button>
            </div>
            <div>
                <VehicleList v-model="vehicle_selected" :vehicles="vehicles"/>
            </div>
            <!-- Pagination -->
            <div class="flex justify-center pb-6" v-if="num_vehicles > perPage">
                <pagination v-model="page" :noArrows="true" :perPage="perPage" :total="num_vehicles"/>
            </div>
        </div>
        <div slot="footer" class="flex justify-end items-center gap-4">
            <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
            <button @click="closeModal()"
                class="btn-secondary font-bold py-2 px-4 rounded-xl">
                {{ $t("cancel") }}
            </button>
            <button @click="assignVehicle()" class="btn-primary font-bold py-2 px-4 rounded-xl">
                {{ $t("assign_vehicle") }}
            </button>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import VehicleList from "@/components/pages/Services/Modals/VehicleList.vue";
import { getProviderVehicles } from '@/services/vehicles';
import { assignServicesToVehicle } from '@/services/services';
import authMixin from "@/mixins/auth";
import paginationMixin from "@/mixins/pagination";
import { Roles } from '@/config';
export default {
    props: ["services"],
    mixins: [authMixin, paginationMixin],
    components: {
		Modal,
        VehicleList,
	},
    data() {
        return {
            loading: false,
            search: "",
            vehicle_selected: null,
            num_vehicles: 0,
            vehicles: [],
        };
    },

    computed: {
        servicesUUIDs() {
            let servicesUUIDs = [];
            for (const service of this.services) {
                servicesUUIDs.push(service.idService);
            }
            return servicesUUIDs;
        },
    },

    watch: {
		page() {
            this.fetchVehicles();
        }
	},

    created: async function () {
        await this.fetchVehicles();
    },

    methods: {
        closeModal() {
            this.$emit('close');
        },

        async filter() {
            await this.fetchVehicles();
        },

        async clearFilter() {
            this.search = "";
            await this.fetchVehicles();
        },

        async fetchVehicles() {
            this.loading = true;
            // Check user role
            const currentUserRole = await this.getCurrentUserRole();
            let filterIdProvider;
            if (currentUserRole == Roles.PROVIDERADMIN) {
                filterIdProvider = await this.getCurrentUserProvider();
            } else {
                filterIdProvider = this.services[0].provider.idProvider;
            }
            const response = await getProviderVehicles(filterIdProvider, this.page, this.search);
            this.num_vehicles = response.numVehicles;
            this.vehicles = response.vehicles;
            this.loading = false;
        },

        async assignVehicle() {
            this.loading = true;
            if (!this.vehicle_selected) {
                this.$noty.warning(this.$t("warning.no_vehicle"));
                this.loading = false;
                return;
            }
            const response = await assignServicesToVehicle(this.vehicle_selected, this.servicesUUIDs);
            if (response) {
                this.$noty.success(this.$t('success.assign_vehicle'));
                this.$emit('close');
            } else {
                this.$noty.error(this.$t('error.saved'));
            }
            this.loading = false;
        }
    }
}
</script>