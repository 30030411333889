<template>
	<div>
		<!-- Toolbar -->
		<div class="bg-white pt-6 md:px-12 pb-3">
			<router-link :to="{ name: $i18n.locale + '.provider_form', params: { creating: true } }" tag="button"	
				class="btn-add float-right">
				{{ $t("add") }}
			</router-link>
			<md-field>
				<label>{{ $t("enter_text") }}</label>
				<md-input v-model="search" maxlength="50"></md-input>
			</md-field>
			<div class="flex justify-end items-center gap-10">
				<!-- Search buttons -->
				<div class="grow flex gap-2">
					<md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
					<button @click="clearFilter()" class="btn-secondary font-bold py-2 px-4 rounded-xl md:w-40">
						{{ $t("delete_filter") }}
					</button>
					<button @click="filter()" class="btn-primary font-bold py-2 px-4 rounded-xl">
						{{ $t("search") }}
					</button>
				</div>
			</div>
		</div>
		<!-- Table -->
		<div class="pt-6 md:px-12">
			<table class="table-rows-rounded min-w-full">
				<thead class="underline text-center">
					<tr>
						<th>
							{{ $t("name") }}
						</th>
						<th>
							{{ $t("identifier") }}
						</th>
						<th>
							{{ $t("haulier") }}
						</th>
						<th>
							<!-- Buttons -->
						</th>
					</tr>
				</thead>
				<tbody class="text-center">
					<tr v-for="(provider, index) in providers" :key="index">
						<td>
							{{ provider.name }}
						</td>
						<td>{{ provider.idCLEAR }}</td>
						<td>{{ provider.haulierCode }}</td>
						<td class="text-right">
							<div class="table-crud-buttons">
								<router-link tag="button" :to="{ 
									name: $i18n.locale + '.provider_form',
										params: {
											provider: provider,
											view: true,
										},
									}">
									<img :src="require('@/assets/icons/icons_actions_view.svg')"/>
								</router-link>
								<router-link tag="button" :to="{ 
									name: $i18n.locale + '.provider_form',
										params: {
											provider: provider,
											editing: true,
										},
									}">
									<img :src="require('@/assets/icons/icons_actions_edit.svg')"/>
								</router-link>
								<button @click="confirmDelete(provider)">
									<img :src="require('@/assets/icons/icons_actions_delete.svg')"/>
								</button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<!-- Pagination -->
            <div class="flex justify-center pb-6">
                <pagination v-model="page" :noArrows="true" :perPage="perPage" :total="num_providers"/>
            </div>
		</div>
		<modal v-if="showConfirm" @close="showConfirm = false">
			<h3 slot="header">
				{{ $t("confirm") }}
			</h3>
			<div slot="body">
				<i18n path="confirm_messages.delete_provider" tag="p">
					<template #provider>
						{{ providerToDelete.name }}
					</template>
				</i18n>
			</div>
			<div slot="footer" class="flex gap-4">
				<button @click="showConfirm = false"
					class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-xl">
					{{ $t("cancel") }}
				</button>
				<button @click="deleteProvider(providerToDelete)"
					class="btn-secondary font-bold py-2 px-4 rounded-xl">
					{{ $t("delete") }}
				</button>
			</div>
		</modal>
	</div>
</template>
<script>
import Modal from '@/components/Modal.vue';
import { getProviders, deleteProvider } from '@/services/providers';
import authMixin from "@/mixins/auth";
import paginationMixin from "@/mixins/pagination";
export default {
	mixins: [authMixin ,paginationMixin],
	components: {
		Modal,
	},
	data() {
		return {
			loading: false,
			showConfirm: false,
			providerToDelete: {},
			search: "",
			num_providers: 0,
			providers: [],
		};
	},

	watch: {
		page() {
            this.fetchProviders();
        },
	},

	created: async function () {
		this.checkPermissions();
		await this.fetchProviders();
	},

	methods: {
		confirmDelete(provider) {
			this.providerToDelete = provider;
			this.showConfirm = true;
		},

		async filter () {
			this.fetchProviders();
		},

		async clearFilter() {
			this.search = "";
			this.fetchProviders();
		},

		async deleteProvider(provider) {
			this.loading = true;
			await deleteProvider(provider);
			this.showConfirm = false;
			this.providers = await getProviders();
			this.providerToDelete = {};
			this.$noty.success(this.$t('success.provider_deleted'));
			this.fetchProviders();
			this.loading = false;
		},

		async fetchProviders() {
			this.loading = true;
			const response = await getProviders(this.page, this.search);
			this.num_providers = response.numProviders;
			this.providers = response.providers;
			this.loading = false;
		}
	},
}
</script>