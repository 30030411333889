var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white pt-6 pb-12 md:px-12"},[_c('div',{staticClass:"flex justify-end"},[_c('a',{staticClass:"back-button",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("arrow_back")]),_vm._v(" "+_vm._s(_vm.$t("back"))+" ")])]),_c('ValidationObserver',{ref:"form",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('md-field',{class:[
            { 'md-valid': errors['name'] && !errors['name'][0] && _vm.touchedFields.name },
            { 'md-error': errors['name'] && errors['name'][0] }
            ]},[_c('label',{attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t('fields.name')))]),_c('validation-provider',{attrs:{"name":"name","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var touched = ref.touched;
return [_c('md-input',{attrs:{"name":"name","type":"text","placeholder":_vm.$t('fields_text.name'),"id":"name","disabled":_vm.loading},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"error"},[_vm._v("close")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('md-field',{class:[
                { 'md-valid': errors['family_name'] && !errors['family_name'][0] && _vm.touchedFields.family_name },
                { 'md-error': errors['family_name'] && errors['family_name'][0] }
                ]},[_c('label',{attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t('fields.family_name')))]),_c('validation-provider',{attrs:{"name":"family_name","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var touched = ref.touched;
return [_c('md-input',{attrs:{"name":"family_name","type":"text","placeholder":_vm.$t('fields_text.family_name'),"id":"family_name","disabled":_vm.loading},model:{value:(_vm.family_name),callback:function ($$v) {_vm.family_name=$$v},expression:"family_name"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"error"},[_vm._v("close")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',[_c('md-field',{class:[
                { 'md-valid': errors['email'] && !errors['email'][0] && _vm.touchedFields.email },
                { 'md-error': errors['email'] && errors['email'][0] }
                ]},[_c('label',{attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t('fields.email')))]),_c('validation-provider',{attrs:{"name":"email","rules":"required|email","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var touched = ref.touched;
return [_c('md-input',{attrs:{"name":"email","type":"email","placeholder":_vm.$t('fields_text.email'),"id":"email","disabled":_vm.loading},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"error"},[_vm._v("close")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',[_c('md-field',{class:[
                { 'md-valid': errors['password'] && !errors['password'][0] && _vm.touchedFields.password },
                { 'md-error': errors['password'] && errors['password'][0] }
                ],attrs:{"slot":"inputs"},slot:"inputs"},[_c('label',{attrs:{"for":"password"}},[_vm._v(_vm._s(_vm.$t('fields.password')))]),_c('validation-provider',{ref:"password",attrs:{"name":"password","rules":"required|min:8|max:15|complex_password","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var touched = ref.touched;
return [_c('md-input',{attrs:{"name":"password","type":"password","placeholder":_vm.$t('fields.password'),"id":"password","disabled":_vm.loading},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"error"},[_vm._v("close")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',[_c('md-field',{class:[
                { 'md-valid': errors['c_password'] && !errors['c_password'][0] && _vm.touchedFields.c_password },
                { 'md-error': errors['c_password'] && errors['c_password'][0] }
                ],attrs:{"slot":"inputs"},slot:"inputs"},[_c('label',{attrs:{"for":"password"}},[_vm._v(_vm._s(_vm.$t('fields.password_confirm')))]),_c('validation-provider',{attrs:{"name":"password_confirm","rules":"required|confirmed:password","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var touched = ref.touched;
return [_c('md-input',{attrs:{"name":"password_confirm","type":"password","placeholder":_vm.$t('fields.password_confirm'),"id":"c_password","disabled":_vm.loading},model:{value:(_vm.c_password),callback:function ($$v) {_vm.c_password=$$v},expression:"c_password"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"error"},[_vm._v("close")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[(!_vm.onlyDrivers)?_c('md-field',{class:[
                { 'md-valid': errors['role'] && !errors['role'][0] && _vm.touchedFields.role },
                { 'md-error': errors['role'] && errors['role'][0] }
                ],attrs:{"slot":"inputs"},slot:"inputs"},[_c('validation-provider',{attrs:{"name":"role","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',{attrs:{"for":"movie"}},[_vm._v(_vm._s(_vm.$t('fields.role')))]),_c('md-select',{attrs:{"name":"role","id":"role","placeholder":_vm.$t('fields.role')},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}},_vm._l((_vm.roles),function(role,index){return _c('md-option',{key:index,attrs:{"value":role}},[_vm._v(" "+_vm._s(_vm.$t('roles.' + role))+" ")])}),1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('md-field',{class:[
                { 'md-valid': errors['provider'] && !errors['provider'][0] && _vm.touchedFields.provider },
                { 'md-error': errors['provider'] && errors['provider'][0] }
                ],attrs:{"slot":"inputs"},slot:"inputs"},[_c('validation-provider',{attrs:{"name":"provider","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',{attrs:{"for":"movie"}},[_vm._v(_vm._s(_vm.$t('fields.provider')))]),_c('md-select',{attrs:{"name":"provider","id":"provider","placeholder":_vm.$t('fields.provider'),"disabled":_vm.forceProvider || _vm.disableProvider},model:{value:(_vm.provider),callback:function ($$v) {_vm.provider=$$v},expression:"provider"}},_vm._l((_vm.providers),function(provider,index){return _c('md-option',{key:index,attrs:{"value":provider.idProvider}},[_vm._v(" "+_vm._s(provider.name)+" ")])}),1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('md-field',[_c('validation-provider',{attrs:{"name":"logistics_centers","rules":'',"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',{attrs:{"for":"movie"}},[_vm._v(_vm._s(_vm.$t('fields.logistics_centers')))]),_c('md-select',{attrs:{"name":"logistics_centers","id":"logistics_centers","placeholder":_vm.$t('fields.logistics_centers'),"multiple":"","disabled":_vm.loading},model:{value:(_vm.selectedCenters),callback:function ($$v) {_vm.selectedCenters=$$v},expression:"selectedCenters"}},_vm._l((_vm.logisticsCenters),function(center,index){return _c('md-option',{key:index,attrs:{"value":center.code}},[_vm._v(" "+_vm._s(center.name)+" ")])}),1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('button',{staticClass:"float-right btn-primary font-bold py-2 px-4 rounded-xl",attrs:{"type":"submit","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }