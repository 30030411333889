<template>
    <div class="bg-white pb-4 md:px-12">
        <!-- Back button -->
        <div class="flex justify-end">
            <a class="back-button" @click="$router.go(-1)">
                <i class="material-icons">arrow_back</i>
                {{ $t("back") }}
            </a>
        </div>
        <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
            <!-- Licence Plate-->
            <div>
                <md-field :class="[
                { 'md-valid': errors['license_plate'] && !errors['license_plate'][0] && touchedFields.name },
                { 'md-error': errors['license_plate'] && errors['license_plate'][0] }
                ]">
                    <label for="license_plate">{{ $t('fields.license_plate') }}</label>
                    <validation-provider name='license_plate' rules="required" v-slot="{ errors, touched }" mode="aggressive">
                        <md-input v-model="license_plate" name="license_plate" type="text" :placeholder="$t('fields_text.license_plate')" id="license_plate" :disabled="loading || view"></md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <!-- Motorized & active -->
            <div class="grid grid-cols-2 gap-4">
                <md-field>
                    <validation-provider name='motorized' rules="required" v-slot="{ errors }" mode="aggressive">
                        <label for="movie">{{ $t('fields.motorized') }}</label>
                        <md-select v-model="motorized" name="motorized" id="motorized" :placeholder="$t('fields.motorized')" :disabled="loading || view">
                            <md-option :value="true">
                                {{ $t("yes") }}
                            </md-option>
                            <md-option :value="false">
                                {{ $t("no") }}
                            </md-option>
                        </md-select>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>

                <md-field>
                    <validation-provider name='active' rules="required" v-slot="{ errors }" mode="aggressive">
                        <label for="movie">{{ $t('fields.active') }}</label>
                        <md-select v-model="active" name="active" id="motorized" :placeholder="$t('fields.active')" :disabled="loading || view">
                            <md-option :value="true">
                                {{ $t("yes") }}
                            </md-option>
                            <md-option :value="false">
                                {{ $t("no") }}
                            </md-option>
                        </md-select>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>

            <!-- Provider & Haulier code -->
            <div class="grid grid-cols-2 gap-4">
                <md-field>
                    <validation-provider name='provider' rules="required" v-slot="{ errors }" mode="aggressive">
                        <label for="movie">{{ $t('fields.provider') }}</label>
                        <md-select v-model="provider" name="provider" id="provider" :placeholder="$t('fields.provider')" :disabled="loading || view || forceProvider">
                            <md-option v-for="(provider, index) in providers" :key="index" :value="provider.idProvider">
                                {{ provider.name }}
                            </md-option>
                        </md-select>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>

                <md-field>
                    <label for="haulier_code">{{ $t('fields.haulier_code') }}</label>
                    <md-input v-model="haulier_code" name="haulier_code" type="text" :placeholder="$t('fields.haulier_code')" id="haulier_code" :disabled="true"></md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </md-field>
            </div>

            <div class="flex justify-end gap-4 mt-4">
                <button v-show="editing || view" @click.prevent="showConfirm = true"
                    class="btn-secondary font-bold py-2 px-4 rounded-xl md:w-40" :disabled="loading">
                    {{ $t("delete") }}
                </button>
                <button @click.prevent="save()" type="submit" class="btn-primary font-bold py-2 px-4 rounded-xl" :disabled="loading || view">
                    {{ $t("save") }}
                </button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import { createVehicle, updateVehicle } from '@/services/vehicles';
import { getServiceFilters } from "@/services/services";
import authMixin from "@/mixins/auth";
import { Roles } from '@/config';
export default {
    mixins: [authMixin],
    props: ["vehicle", "creating", "editing", "view"],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
    },

    data () {
        return {
            loading: false,
            showConfirm: false,
            forceProvider: false,
            touchedFields: {
                license_plate: false,
                client: false,
                motorized: false,
                active: false,
                provider: false,
            },
            idVehicle: null,
            license_plate: "",
            haulier_code: "",
            client: "",
            motorized: null,
            active: null,
            provider: "",
            providers: [],
        };
    },

    watch: {
        provider: function() {
            const idProviderSelected = this.provider;
            const providerSelected = this.providers.filter(function (provider) {
				return provider.idProvider == idProviderSelected;
			});
            if (providerSelected.length == 1) {
                this.haulier_code = providerSelected[0].haulierCode;
            }
        }
    },

    created: async function () {
        // Redirect to providers without mode props
        if (this.creating == undefined && this.editing == undefined && this.view == undefined) {
            this.$router.push({ name: this.$i18n.locale + '.vehicles' });
        }
        if (this.vehicle) {
            this.idVehicle = this.vehicle.idVehicle;
            this.license_plate = this.vehicle.licensePlate;
            this.motorized = this.vehicle.motorized;
            this.active = this.vehicle.active;
            this.haulier_code = this.vehicle.provider.haulierCode;
            this.provider = this.vehicle.provider.idProvider;
        }
        await this.fetchProviders();
        // Check user role
        const currentUserRole = await this.getCurrentUserRole();
        if (currentUserRole == Roles.PROVIDERADMIN) {
            this.forceProvider = true;
            this.provider = await this.getCurrentUserProvider();
        }
    },

    methods: {
        async save() {
            this.loading = true;
            var valid = await this.$refs.form.validate();
            if (valid) {
                const vehicleToSave = {
                    idVehicle: this.idVehicle,
                    licensePlate: this.license_plate,
                    motorized: this.motorized,
                    provider: {
                        idProvider: this.provider,
                    },
                    active: this.active,
                };
                if (this.creating) {
                    const response = await createVehicle(vehicleToSave);
                    if (response) {
                        this.$noty.success(this.$t('success.vehicle_saved'));
                        this.clearForm();
                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                } else if (this.editing) {
                    const idProvider = this.vehicle.provider.idProvider;
                    const response = await updateVehicle(idProvider, vehicleToSave);
                    if (response) {
                        this.$noty.success(this.$t('success.vehicle_saved'));
                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                }

            }
            this.loading = false;
        },

        clearForm() {
            this.license_plate = "";
            this.motorized = null;
            this.active = null;
            this.provider = "";
            this.touchedFields = {
                license_plate: false,
                client: false,
                motorized: false,
                active: false,
                provider: false,
            };
            this.$refs.form.reset();
        },

        async fetchProviders() {
            const serviceFilters = await getServiceFilters();
            this.providers = serviceFilters.providers;
        },
    }
}
</script>
