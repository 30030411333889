<template>
    <modal class="modal-assign-user">
        <h3 slot="header" class="modal-title">
            {{ $t("change_date") }}
        </h3>
        <div slot="body">
            <form @submit.prevent="changeDate()">
                <md-datepicker v-model="date" :md-model-type="String">
                    <label>{{ $t("date") }} (dd/mm/yyyy)</label>
                    <span class="md-error">
                        {{ errorDateMessage }}
                    </span>
                </md-datepicker>
            </form>
        </div>
        <div slot="footer" class="flex justify-end items-center gap-4">
            <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
            <button @click="closeModal()"
                class="btn-secondary font-bold py-2 px-4 rounded-xl">
                {{ $t("cancel") }}
            </button>
            <button @click="changeDate()" class="btn-primary font-bold py-2 px-4 rounded-xl">
                {{ $t("change_date") }}
            </button>
        </div>
    </modal>
</template>

<script>
import { changeServiceDate } from '@/services/services';
import Modal from '@/components/Modal.vue';
import localeMixin from "@/mixins/locale";
export default {
    props: ["services"],
    mixins: [localeMixin],
    components: {
		Modal,
	},
    data() {
        return {
            loading: false,
            date: null,
            errorDateMessage: "",
        };
    },

    computed: {
        servicesUUIDs() {
            let servicesUUIDs = [];
            for (const service of this.services) {
                servicesUUIDs.push(service.idService);
            }
            return servicesUUIDs;
        },
    },

    methods: {
        closeModal() {
            this.$emit('close');
        },

        async changeDate() {
            this.loading = true;
            if (!this.date) {
                this.errorDateMessage = this.$t("warning.no_date");
            } else {
                this.errorDateMessage = "";
                const date = this.parseSearchDate(this.date);
                const response = await changeServiceDate(this.servicesUUIDs, date);
                if (response) {
                    this.$noty.success(this.$t('success.change_date'));
                    this.$emit('close');
                } else {
                    this.$noty.error(this.$t('error.saved'));
                }
            }
            this.loading = false;
        },

        parseSearchDate(date) {
            const momentObject = this.moment(date, "DD/MM/YYYY");
            const dateISO = momentObject.format("YYYY-MM-DD");
            return dateISO;
        },
    }

}
</script>