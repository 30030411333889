<template>
    <div class="md-layout text-center">
        <div class="md-layout-item md-size-70 md-medium-size-70 md-small-size-70 md-xsmall-size-100">
            <!-- Remember Password with Wizard -->
            <!-- <simple-wizard :prevButtonText="$t('previous')" :nextButtonText="$t('next')" :finishButtonText="$t('finish')">
                <template slot="header">
                    <h3 class="title">{{ $t('forgotten_password') }}</h3>
                    <h5 class="category">
                        {{ $t('forgotten_password') }}
                    </h5>
                </template>
                <wizard-tab :before-change="() => validateStep('step1')">
                    <template slot="label">
                        {{ $t('request_code') }}
                    </template>
                    <request-step ref="step1" @on-validated="onStepValidated"></request-step>
                </wizard-tab>
                <wizard-tab :before-change="() => validateStep('step2')">
                    <template slot="label">
                        {{ $t('reset_pass') }}
                    </template>
                    <reset-step ref="step2" @on-validated="wizardComplete"></reset-step>
                </wizard-tab>
            </simple-wizard> -->
            <!-- Remember password with Login Card -->
            <ValidationObserver v-slot="{ handleSubmit, errors }">
                <form @submit.prevent="handleSubmit(remember)">
                    <login-card>
                        <div slot="title" class="pt-4">
                            <img src="@/assets/logo-prezero-4drivers.svg" alt="Pre Zero 4 Drivers">
                            <!-- <h3 class="pt-4 text-4xl uppercase">
                                4 Drivers
                            </h3> -->
                        </div>
                        
                        <md-field slot="inputs" :class="[
                                { 'md-valid': errors['email'] && !errors['email'][0] },
                                { 'md-error': errors['email'] && errors['email'][0] }
                            ]">
                            <label for="email">{{ $t('fields.email') }}</label>
                            <validation-provider name='email' rules="required|email" v-slot="{ errors }">
                                <md-input v-model="email" name="email" type="email" :placeholder="$t('fields.email')" id="email" :disabled="loading"></md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors[0]">close</md-icon>
                                </slide-y-down-transition>
                                <span class="md-error">{{ errors[0] }}</span>
                            </validation-provider>
                        </md-field>
                        <div class="w-full" slot="footer">
                            <button type="submit" class="w-full btn-primary font-bold py-2 px-4 rounded-lg" id="btnLogin" :disabled="loading">
                                {{ $t('request_code') }}
                            </button>
                            <div class="text-left mt-2">
                                <router-link :to="{ name: $i18n.locale + '.reset-password' }" class="link-underline">
                                    <!-- {{ $t('forgotten_password_message') }} -->
                                    {{ $t('already_code') }}
                                </router-link>
                            </div>
                        </div>
                    </login-card>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
// import RequestStep from "./Wizard/RequestStep.vue";
// import ResetStep from "./Wizard/ResetStep.vue";
// import SimpleWizard from "../../Wizard/Wizard.vue";
// import WizardTab from "../../Wizard/WizardTab.vue";
import { Auth } from 'aws-amplify';
import LoginCard from "../../Cards/LoginCard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";

export default {
    data() {
        return {
            loading: false,
            email: "",
            wizardModel: {}
        };
    },
    components: {
        // RequestStep,
        // ResetStep,
        // SimpleWizard,
        // WizardTab,
        LoginCard,
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition
    },
    methods: {
        validateStep(ref) {
            return this.$refs[ref].validate();
        },
        onStepValidated( /*validated, model*/ ) {
            console.log('VALIDATED');
            //this.wizardModel = { ...this.wizardModel, ...model };
        },
        wizardComplete() {
            this.$noty.success(this.$t('success.forgot_pass_confirm'));
            //this.$router.push({ name: this.$i18n.locale + '.login' });
        },
        async remember() {
            this.loading = true;
            try {
                let username = this.email;
                await Auth.forgotPassword(username);
                this.$noty.success(this.$t('success.request_code_sent'));
            } catch (err) {
                console.error('Error reseting pass', err);
                var errorMsg = '';
                switch (err.code) {
                    case 'LimitExceededException':
                        errorMsg = this.$t('error.limit_exceeded');
                        break;
                    default:
                        errorMsg = this.$t('error.forgot_pass');
                }
                this.$noty.error(errorMsg);
            }
            this.loading = false;
        },
    }
};
</script>