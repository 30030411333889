import Pagination from "@/components/Pagination.vue";

export default {
    components: {
        Pagination,
	},

    data() {
        return {
            page: 1,
            perPage: 10,
        };
    },

    computed: {
        minPosition() {
            return (this.page * this.perPage) - this.perPage;
        },
        maxPosition() {
            return this.perPage * this.page;
        }
    },

    methods: {
        getLocalStoragePage(nameStore) {
            const page = localStorage.getItem(nameStore);
            return page ? Number(page) : 1;
        },

        setLocalStoragePage(nameStore, page) {
            localStorage.setItem(nameStore, page);
        },
    },
}