var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout text-center"},[_c('div',{staticClass:"md-layout-item md-size-70 md-medium-size-70 md-small-size-70 md-xsmall-size-100"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var errors = ref.errors;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.remember)}}},[_c('login-card',[_c('div',{staticClass:"pt-4",attrs:{"slot":"title"},slot:"title"},[_c('img',{attrs:{"src":require("@/assets/logo-prezero-4drivers.svg"),"alt":"Pre Zero 4 Drivers"}})]),_c('md-field',{class:[
                            { 'md-valid': errors['email'] && !errors['email'][0] },
                            { 'md-error': errors['email'] && errors['email'][0] }
                        ],attrs:{"slot":"inputs"},slot:"inputs"},[_c('label',{attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t('fields.email')))]),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('md-input',{attrs:{"name":"email","type":"email","placeholder":_vm.$t('fields.email'),"id":"email","disabled":_vm.loading},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"error"},[_vm._v("close")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full",attrs:{"slot":"footer"},slot:"footer"},[_c('button',{staticClass:"w-full btn-primary font-bold py-2 px-4 rounded-lg",attrs:{"type":"submit","id":"btnLogin","disabled":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('request_code'))+" ")]),_c('div',{staticClass:"text-left mt-2"},[_c('router-link',{staticClass:"link-underline",attrs:{"to":{ name: _vm.$i18n.locale + '.reset-password' }}},[_vm._v(" "+_vm._s(_vm.$t('already_code'))+" ")])],1)])],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }