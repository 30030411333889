<template>
    <md-toolbar md-elevation="0" class="md-transparent" :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute
    }">
        <div class="md-toolbar-row">
            <div class="md-toolbar-section-start">
                <h3 class="page-title md:text-4xl">
                    <img v-if="pageIcon" :src="require('@/assets/icons/' + pageIcon)"/>
                    <span>{{ pageTitle }}</span>
                </h3>
            </div>
            <div class="md-toolbar-section-end">
                <md-button class="md-just-icon md-round md-simple md-toolbar-toggle" :class="{ toggled: $sidebar.showSidebar }" @click="toggleSidebar">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </md-button>
                <div class="md-collapse">
                    <!-- <div class="md-autocomplete">
                        <md-autocomplete class="search" v-model="selectedEmployee" :md-options="employees" :md-open-on-focus="false">
                            <label v-if="$route.meta.rtlActive">بحث...</label>
                            <label v-else>Search...</label>
                        </md-autocomplete>
                    </div> -->
                    <md-list>
                        <!-- <md-list-item :href="getRouteHref('dashboard')">
                            <i class="material-icons">dashboard</i>
                            <p class="hidden-lg hidden-md">Dashboard</p>
                        </md-list-item> -->
                        <!-- <li class="md-list-item">
                            <a href="#/components/notifications" class="md-list-item-router md-list-item-container md-button-clean dropdown">
                                <div class="md-list-item-content">
                                    <drop-down direction="down">
                                        <md-button slot="title" class="md-button md-just-icon md-simple" data-toggle="dropdown">
                                            <md-icon>notifications</md-icon>
                                            <span class="notification">5</span>
                                            <p class="hidden-lg hidden-md">Notifications</p>
                                        </md-button>
                                        <ul class="dropdown-menu dropdown-menu-right">
                                            <li><a href="#">Mike John responded to your email</a></li>
                                            <li><a href="#">You have 5 new tasks</a></li>
                                            <li><a href="#">You're now friend with Andrew</a></li>
                                            <li><a href="#">Another Notification</a></li>
                                            <li><a href="#">Another One</a></li>
                                        </ul>
                                    </drop-down>
                                </div>
                            </a>
                        </li> -->
                        <!-- <md-list-item :href="getRouteHref('profile')">
                            <i class="material-icons">person</i>
                            <p class="hidden-lg hidden-md">Profile</p>
                        </md-list-item> -->
                        <md-list-item @click="showConfirm = true">
                            <p class="mr-3">{{ $t("logout") }}</p>
                            <i class="material-icons">logout</i>
                        </md-list-item>
                    </md-list>
                </div>
            </div>
        </div>
        <modal v-if="showConfirm" @close="showConfirm = false">
			<h3 slot="header">
				{{ $t("confirm") }}
			</h3>
			<div slot="body">
				{{ $t("confirm_messages.logout") }}
			</div>
			<div slot="footer" class="flex gap-4">
				<button @click="showConfirm = false"
					class="btn-secondary font-bold py-2 px-4 rounded-xl">
					{{ $t("cancel") }}
				</button>
				<button @click="logout"
					class="btn-primary font-bold py-2 px-4 rounded-xl">
					{{ $t("logout") }}
				</button>
			</div>
		</modal>
    </md-toolbar>
</template>
<script>
import Modal from '@/components/Modal.vue';
import linkMixin from "../../../mixins/link";
import { Auth } from 'aws-amplify';
export default {
    mixins: [linkMixin],
    components: {
		Modal,
	},
    computed: {
        pageTitle: function() {
            let route = this.$route.name.substr(3);
            return this.$t('routes.' + route + '.title');
        },
        pageIcon: function() {
            let route = this.$route.name.substr(3);
            let icon;
            switch (route) {
                case 'users':
                case 'user_create':
                case 'user_detail':
                    icon = 'icons_menu_users.svg';
                    break;
                case 'drivers':
                case 'driver_create':
                case 'driver_detail':
                    icon = 'icons_menu_driver.svg';
                    break;
                case 'providers':
                case 'provider_form':
                    icon = 'icons_menu_provider.svg';
                    break;
                case 'services':
                case 'service_detail':
                    icon = 'icons_menu_services.svg'
                    break;
                case 'synchronization':
                    icon = 'icons_menu_sync.svg';
                    break;
                case 'vehicles':
                case 'vehicle_form':
                    icon = 'icons_menu_vehicles.svg';
                    break;
                default:
                    icon = false;
                    break;
            }
            return icon;
        }
    },
    data() {
        return {
            showConfirm: false,
            selectedEmployee: "",
            employees: [
                "Jim Halpert",
                "Dwight Schrute",
                "Michael Scott",
                "Pam Beesly",
                "Angela Martin",
                "Kelly Kapoor",
                "Ryan Howard",
                "Kevin Malone"
            ]
        };
    },
    methods: {
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        },
        cleanAllCookies() {
            this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
        },
        async logout() {
            await Auth.signOut();
            // Remove persist local storage
            localStorage.removeItem('serviceFilters');
            localStorage.removeItem('servicesPage');
            this.cleanAllCookies();
            this.$router.push({ name: this.$i18n.locale + '.login' });
        }
    }
};
</script>