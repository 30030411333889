import { API, Auth } from 'aws-amplify';
import util from 'util';

/**
 * Get users list
 *
 * @returns {Promise} Promise - object with the result of the api call response
 */
const getUsers = async () => {
    let users = [];
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/user'];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        users = response.data;
    } catch (error) {
        console.error(
            "Error getting users: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    
    return users;
}

/**
 * Create user
 *
 * @param {Object} user - user object
 * @returns {Promise} Promise - object with the result of the api call response
 */
const createUser = async (user) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/user/'];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: user,
    };
    try {
        var response = await API.post(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error creating user: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
}

/**
 * Update user
 *
 * @param {Object} user - user object
 * @returns {Promise} Promise - object with the result of the api call response
 */
const updateUser = async (user) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/user/' + user.idUser];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: user,
    };
    try {
        var response = await API.put(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error updating user: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
}

/**
 * Delete user
 *
 * @param {Object} user - user object
 * @returns {Promise} Promise - object with the result of the api call response
 */
const deleteUser = async (user) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/user/' + user.idUser];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.del(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error deleting user: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
}

/**
 * Get User Logisctics Centers
 *
 * @param {Object} user - user object
 * @returns {Promise} Promise - object with the result of the api call response
 */
const getUserLogisticsCenters = async (user) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/user/' + user.idUser + '/logisticsCenter'];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error getting user logisctics centers: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
}

/**
 * Save User Logisctics Centers
 *
 * @param {Object} user - user object
 * @param {Array} logisticsCenters - user object
 * @returns {Promise} Promise - object with the result of the api call response
 */
const saveUserLogisticsCenters = async (user, logisticsCenters) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const path = ['/user/' + user.idUser + '/logisticsCenter'];
    var headers = {
        "x-api-key": API_KEY,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: logisticsCenters,
    };
    try {
        var response = await API.post(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error saving user logisctics centers: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
}

export {
    getUsers,
    createUser,
    updateUser,
    deleteUser,
    getUserLogisticsCenters,
    saveUserLogisticsCenters,
}